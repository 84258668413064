import React, {useState} from "react";
import Q_Card from "./Q_Card";
import "./Q_Cards.scss";

function Q_Cards({cardsArr, isMultiSelect, selected}) {
  const [selectedCards, setSelectedCards] = useState(selected ? [selected] : []);

  const handleCardClick = (card) => {
    if (isMultiSelect) {
      if (selectedCards.includes(card)) {
        setSelectedCards(selectedCards.filter((selectedCard) => selectedCard !== card));
      } else {
        setSelectedCards([...selectedCards, card]);
      }
    } else {
      setSelectedCards([card]);
    }
    card.onClick();
  };

  return (
    <div className="Q_Cards ">
      {cardsArr.map((card, index) => (
        <Q_Card
          key={index}
          card={card}
          isSelected={selectedCards.map(card => card.id).includes(card.id)}
          onClick={() => handleCardClick(card)}
        />
      ))}
    </div>
  );
}

export default Q_Cards;


// import React, { useState } from 'react';
// import './Q_CardsSelect.scss';
// import Card from "../Card/Card";
// import {CardType, Locale, UserType} from "../../graphql/types";

// const Q_CardsSelect = ({ label, onInputChange, onSelect, selected, cardProps, UserType, cards}) => {

//       const handleRangeSelection = (selected) => {
//             if (selected === selectedRange) {
//                   // Deselect the button if it's already selected
//                   setSelectedRange(null);
//             } else {
//                   setSelectedRange(selected);
//                   setRating(selected);
//                   onSelect(selected);
//             }
//             };

//     const Q_Card = ({onClick,emoji,classes,title}) => {
//       return (
//             <>
//             <div className={"q_card " + classes}>
//                   <div>{emoji}</div>
//                   <div>{title}</div>
//             </div>
//             </>
//       )
//     }


//       return (
//     <div className="Q_cards">
//       {cards.map((card, index) => (
//       <Q_Card
//           key={index}
//           onClick={card.onClick}
//           emoji={card.emoji}
//           classes={card.classes}
//           title={card.title}
//       />
//       ))}
//     </div>
//   );
// }

// //     return (

// {/* <div className={'Onboarding--cards'}> */}
//       {/* <Card {...cardProps}
//             onClick={() => onSelect(UserType.ParentAndChild)}
//             emoji={"🧒👨"}
//             classes={selected === UserType.ParentAndChild ? 'selected' : ''}
//             title={"Me and my parent"}/>
//       <Card {...cardProps}
//             onClick={() => onSelect(UserType.Child)}
//             emoji={"🧒"}
//             title={"Just me"}
//             classes={selected === UserType.Child ? 'selected' : ''}/>
//       <Card {...cardProps}
//             onClick={() => onSelect(UserType.Parent)}
//             emoji={"👨"}
//             title={"Parent"}
//             classes={selected === UserType.Parent ? 'selected' : ''}/>
//       <Card {...cardProps}
//             onClick={() => onSelect(UserType.Other)}
//             emoji={"⃛..."}
//             title={"Other"}
//             classes={selected === UserType.Other ? 'selected' : ''}/> */}

// //     </div>
// //   );
// // };

// export default Q_CardsSelect;