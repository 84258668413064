/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const mailFeedback = /* GraphQL */ `
  mutation mailFeedback($feedback: String!) {
    mailFeedback(feedback: $feedback)
  }
`;

export const CreateGuestUser = `
  mutation CreateGuestUser {
    createGuestUser {
      token
      id
      locale
      user {
        id
        locale
        registered
        isGuest
      }
    }
  }
`

export const signUpGuestUser = `
  mutation SignUpGuestUser($userInput: UserInputData!) {
    signUpGuestUser(userInput: $userInput) {
      token
      id
      locale
      user {
        id
        locale
        registered
        isGuest
      }
    }
  }
`;

export const createUser = `
  mutation CreateUser($userInput: UserInputData!) {
    createUser(userInput: $userInput) {
      token
      id
      locale
      user {
        id
        locale
        registered
        isGuest
      }
    }
  }
`;

export const login = `
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      id
      email
      locale
      user {
        id
        locale
        registered
        isOnboardingComplete
      }
    }
}
`

export const mailResetPassword = `
  mutation MailResetPassword($email: String!) {
    mailResetPassword(email: $email)
  }
`

export const updateUserIsSubscribed = `
  mutation UpdateUserIsSubscribed($isSubscribed: Boolean!) {
    setUserSubscription(isSubscribed: $isSubscribed) {
      id
      isSubscribed
      __typename
    }
  }
`

export const updateUserExitPoll = /* GraphQL */ `
  mutation UpdateUserExitPoll($exitPoll: ExitPollInput!) {
    setUserExitPoll(exitPoll: $exitPoll) {
      id
      isSubscribed
      exitPoll {
        subscriptionLikelihood
        reasons
        explanation
      }
      __typename
    }
  }
`;

export const updateUserType = `
  mutation UpdateUserType($type: UserType!) {
    setUserType(type: $type) {
      id
      type
      __typename
    }
  }
`

export const updateUserOnboardingComplete = `
  mutation UpdateUserType($isOnboardingComplete: Boolean!) {
    setUserIsOnboardingComplete(isOnboardingComplete: $isOnboardingComplete) {
      id
      isOnboardingComplete
      __typename
    }
  }
`

export const createRelationship = `
	mutation CreateRelationship($input: CreateRelationshipInput!) {
		createRelationship(input: $input) {
      id
      name
      animalId
      gender
      partnerAnimalId
      partnerGender
      relation
			partnerName
			partnerRelationshipSurvey {
				surveyFeeling {
					cardType
					isReversed
					isReduced
					prefix
					modifier
					emoji
					isPositive
					title
					description
					switchSides
				}
				surveyHappened {
					cardType
					isReversed
					isReduced
					prefix
					modifier
					emoji
					isPositive
					title
					description
					switchSides
				}
				surveyNeeds {
					cardType
					isReversed
					isReduced
					prefix
					modifier
					emoji
					isPositive
					title
					description
					switchSides
				}
			}
		}
	}
`;


export const updateRelationship = `
mutation UpdateRelationship($input: UpdateRelationshipInput!) {
  updateRelationship(input: $input) {
    id
    name
    animalId
    gender
    partnerAnimalId
    partnerGender
    relation
    partnerName
    partnerRelationshipSurvey {
      surveyFeeling {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      surveyHappened {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      surveyNeeds {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
    }
  }
}
`;

export const updateMirror = `
  mutation UpdateMirror($input: updateMirrorInput!){
    updateMirror(input: $input){
      id
      relationshipId
      progress{
        journeyItem
        step
        version
        currentStepNumber
        flowState{
          isCompleted
        }
      }
  createdAt
  updatedAt
  situationName
  whatHappened
  feelTowardsPartner {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyFeelTowardsPartner
  feelTowardsSituation {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyFeelTowardsSituation
  whatYouNeed {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyYouNeed
  whatHope
  whyHope
  believeItCanWork
  myTriggerUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerInsteadUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerHappenMore {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerSummary
  assumePartnerTriggerUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  assumePartnerTriggerInsteadUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  assumePartnerTriggerHappenMore {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  assumePartnerTriggerSummary
  assumeWhatHappened 
        assumeFeelTowardsSituation{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumeWhyFeelTowardsSituation
        assumeFeelTowardsMe{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumeWhyFeelTowardsMe
        assumeWhyPartnerNeed
        assumeWhatPartnerNeed{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
    }
  }
`;

export const addMirror = `
  mutation AddMirror($input: createMirrorInput!){
    addMirror(input: $input)
    {
      id
      relationshipId
      progress{
        journeyItem
        step
      }
  createdAt
  updatedAt
  situationName
  whatHappened
  feelTowardsPartner {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyFeelTowardsPartner
  feelTowardsSituation {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyFeelTowardsSituation
  whatYouNeed {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyYouNeed
  whatHope
  whyHope
  believeItCanWork
  myTriggerUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerInsteadUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerHappenMore {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerSummary
  assumePartnerTriggerUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  assumePartnerTriggerInsteadUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  assumePartnerTriggerHappenMore {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  assumePartnerTriggerSummary
  assumeWhatHappened 
        assumeFeelTowardsSituation{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumeWhyFeelTowardsSituation
        assumeFeelTowardsMe{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumeWhyFeelTowardsMe
        assumeWhyPartnerNeed
        assumeWhatPartnerNeed{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
    }
  }
`;

export const deleteRelationship = /* GraphQL */ `
  mutation deleteRelationship($id: String) {
    deleteRelationship(id: $id)
  }
`;

export const deleteMirror = /* GraphQL */ `
  mutation deleteMirror($input: String) {
    deleteMirror(input: $input)
  }
`;
export const createPlaylist = /* GraphQL */ `
  mutation CreatePlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      id
      createdAt
      owner {
        id
        username
        email
        registered
        locale
        relationships {
          items {
            id
            createdAt
            updatedAt
            name
            animalId
            gender
            partnerAnimalId
            partnerGender
            relation
            partnerName
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            partnerRelationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            partnerTriggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            relationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            triggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            mySideMirrors {
              id
              createdAt
              updatedAt
              situationName
              whatHappened
              feelTowardsPartner {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyFeelTowardsPartner
              feelTowardsSituation {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyFeelTowardsSituation
              whatYouNeed {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyYouNeed
              whatHope
              whyHope
              believeItCanWork
              myTriggerUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerInsteadUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerHappenMore {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerSummary
              partnerTriggerUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerInsteadUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerHappenMore {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerSummary
            }
          }
          nextToken
        }
        playlists {
          items {
            id
            createdAt
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            videos {
              url
              start
              end
              title
            }
            name
            order
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videos {
        url
        start
        end
        title
      }
      name
      order
      updatedAt
    }
  }
`;
export const updatePlaylist = /* GraphQL */ `
  mutation UpdatePlaylist($input: UpdatePlaylistInput!) {
    updatePlaylist(input: $input) {
      id
      createdAt
      owner {
        id
        username
        email
        registered
        locale
        relationships {
          items {
            id
            createdAt
            updatedAt
            name
            animalId
            gender
            partnerAnimalId
            partnerGender
            relation
            partnerName
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            partnerRelationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            partnerTriggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            relationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            triggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            mySideMirrors {
              id
              createdAt
              updatedAt
              situationName
              whatHappened
              feelTowardsPartner {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyFeelTowardsPartner
              feelTowardsSituation {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyFeelTowardsSituation
              whatYouNeed {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyYouNeed
              whatHope
              whyHope
              believeItCanWork
              myTriggerUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerInsteadUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerHappenMore {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerSummary
              partnerTriggerUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerInsteadUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerHappenMore {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerSummary
            }
          }
          nextToken
        }
        playlists {
          items {
            id
            createdAt
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            videos {
              url
              start
              end
              title
            }
            name
            order
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videos {
        url
        start
        end
        title
      }
      name
      order
      updatedAt
    }
  }
`;
export const deletePlaylist = /* GraphQL */ `
  mutation DeletePlaylist($input: DeletePlaylistInput!) {
    deletePlaylist(input: $input) {
      id
      createdAt
      owner {
        id
        username
        email
        registered
        locale
        relationships {
          items {
            id
            createdAt
            updatedAt
            name
            animalId
            gender
            partnerAnimalId
            partnerGender
            relation
            partnerName
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            partnerRelationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            partnerTriggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            relationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            triggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            mySideMirrors {
              id
              createdAt
              updatedAt
              situationName
              whatHappened
              feelTowardsPartner {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyFeelTowardsPartner
              feelTowardsSituation {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyFeelTowardsSituation
              whatYouNeed {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyYouNeed
              whatHope
              whyHope
              believeItCanWork
              myTriggerUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerInsteadUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerHappenMore {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerSummary
              partnerTriggerUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerInsteadUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerHappenMore {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerSummary
            }
          }
          nextToken
        }
        playlists {
          items {
            id
            createdAt
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            videos {
              url
              start
              end
              title
            }
            name
            order
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videos {
        url
        start
        end
        title
      }
      name
      order
      updatedAt
    }
  }
`;
