/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import "./TopNavbar.scss";
import Button from "../../components/Button/Button";
import HomeIcon from "./home_icon.png";
import Drawer from "@material-ui/core/Drawer";
import FeltLogo from "../../components/FeltLogo/FeltLogo";
import Loading from "../../components/Loading/Loading";
import Register from "../../pages/Login/Register";
import Login from "../../pages/Login/Login";
import Modal from "../Modal/Modal";

function changeLanguage(i18n, langId) {
  // console.log('i18n.translator.language', i18n.translator.language);
  if (!langId) {

    if (i18n.translator.language === `en` || !i18n.translator.language) {
      langId = `he`;
    } else {
      langId = `en`;
    }
  }
  i18n.changeLanguage(langId);
  document.body.setAttribute(`lang`, langId);
  return langId;
}


function TopNavbar({
                     border,
                     title,
                     showNavBG,
                     showLogo,
                     promptCancelChances,
                     showSettingsButton,
                     showMenuButton,
                     history,
                     showHomeButton,
                     BackLinkTo,
                     rightButton,
                     brightNav,
                     BackOnClick,
                     changeStep,
                     spacer,
                     user,
                     authContext,
                     showLangButton,
                     showProfileButton,
                     showFeedbackModalButton,
                     setIsFeedbackModalState
                   }) {
  const [navbarState, setNavbarState] = useState({
    isMenuOpen: false,
    title,
    showMenuButton,
    showHomeButton,
    BackLinkTo,
    showLogo,
    brightNav,
    showSettingsButton,
    showLangButton,
    showProfileButton,
    showFeedbackModalButton
  });

  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const loginFunc = () => {user.isGuest && setIsLogin(true) && setIsRegister(false)} //TODO
  
  const {t, i18n} = useTranslation();
  const historyHook = useHistory()

  if (rightButton) {
    const {
      text,
      onClick
    } = rightButton;
  }

  function localeHandler(i18n) {
    const langId = changeLanguage(i18n)
    console.log('changing lang to ', langId);
    authContext.changeLanguage(langId);
  }

  function removeBorder() {
    if (border === false) {
      return {borderBottom: `none`};
    }
  }

  function handlePromptCancelChanges() {
    const message = t(`general.unsavedChanges`)
    if (window.confirm(message)) {
      historyHook.push(BackLinkTo)
      // window.history.goBack(); // TODO: this used to work!
      // return <Redirect to={"www.google.com"} />; TODO: why is this not working??
    } else {

    }
  }

  const toggleDrawer = open => event => {
    setNavbarState({...navbarState, isMenuOpen: open});
  };

  const center = (() => {
    if (showLogo) {
      return (
        <div className="top-navbar--center noselect ">
          <FeltLogo
            color="orange"
          />
        </div>
      )
    } else if (title) {
      return <h1 className={brightNav ? "top-navbar-title__bright noselect " : ""}>{title}</h1>
    } else {
      return (
        <div className="top-navbar--spacer noselect"></div>
      )
    }
  })()
  const leftSide = (() => {

    return (
      <div className="top-navbar--leftSide noselect">
        {
          showMenuButton
            ?
            <div className="top-navbar--menu top-navbar--circle__with-text noselect" onClick={toggleDrawer(true)}>
              <span className="textWebNav">{t(`topnavbar.menu.title`)}</span> &#9776;
            </div>
            : ""
        }
        {
          showHomeButton ?
            <Link
              to={{
                pathname: `/`,
              }}
            >
              <div className="top-navbar--circle noselect">
                <img src={HomeIcon} alt="home icon" width="33" height="30"/>
              </div>
            </Link>
            : ""
        }
        {
          showFeedbackModalButton
            ?
            <a href="https://forms.gle/x5de7YCEMnrsWSJ66" target="_blank">
              <div className="top-navbar--circle__with-text top-navbar--feedback noselect">
                <img className="icon-feedback noselect " src={"/assets/icons/icon-feedback.png"}/>
                <span className="textWebNav noselect ">{t('general.feedback')}</span>
              </div>
            </a>
            : ""
        }
        {
          BackOnClick ?
            <div className="top-navbar--circle noselect " onClick={BackOnClick}>
              <span className="left-chevron">&#8249;</span>
            </div>
            : ""
        }
        {
          BackLinkTo ?
            <div>
              {promptCancelChances ?
                (<div className="top-navbar--circle noselect " onClick={handlePromptCancelChanges}>
                  <span className="left-chevron">&#8249;</span>
                </div>) : (
                  <Link to={BackLinkTo}>
                    <div className="top-navbar--circle noselect ">
                      <span className="left-chevron">&#8249;</span>
                    </div>
                  </Link>)
              }
            </div>
            : ""
        }
      </div>
    )


    if (BackLinkTo) {
      return (
        <div>
          {
            promptCancelChances
              ?
              (
                <div className="top-navbar--circle" onClick={handlePromptCancelChanges}>
                  <span className="left-chevron">&#8249;</span>
                </div>
              )
              :
              (
                <Link
                  to={BackLinkTo}
                >
                  <div className="top-navbar--circle">
                    <span className="left-chevron">&#8249;</span>
                  </div>
                </Link>)
          }
        </div>


      )

    } else if (BackOnClick) {

      return (<div className="top-navbar--circle" onClick={BackOnClick}>
        <span className="left-chevron">&#8249;</span>
      </div>)
    } else if (showHomeButton) {
      return (
        <Link
          to={{
            pathname: `/`,
            // state: { relationship }
          }}
        >
          <div className="top-navbar--circle">
            {/* <span>HOME</span> */}
            <img src="/home_icon.png" alt="home icon" width="33" height="30"/>
          </div>
        </Link>

      )
    } else {
      return (
        <div className="top-navbar--spacer"></div>
      )
    }
  })()

  const rightSide = (() => {
    return (
      <div className="top-navbar--rightSide noselect ">
        {user.isGuest && showProfileButton?
          <>
            <div className="top-navbar--circle__with-text top-navbar--feedback noselect" onClick={() => setIsRegister(true)}>
              <img className="icon-feedback noselect " src={"/assets/icons/profile_missing.png"}/>
              <span className="textWebNav noselect ">{t('general.guest')}</span>
            </div>
            {/* </button> */}
            {/* <button onClick={() => setIsLogin(true)}> log in</button> */}
          </>
          : ""
        }
        {
          showLangButton ?
            <div className="top-navbar--circle noselect " onClick={() => localeHandler(i18n)}>
              <span className="settings-icon">{t(`topnavbar.menu.change_language_button_text`)}</span>
            </div>
            : ""
        }
        {
          rightButton
            ?
            <div>
              <Button
                classes={"top-navbar__button noselect " + (rightButton.color ? "top-navbar__button--color " : "")}
                text={rightButton.text}
                onClick={rightButton.onClick}
              />
            </div>
            : ""
        }
        {
          showSettingsButton
            ?
            <div>
              {<div className="top-navbar--circle noselect " onClick={showSettingsButton.onClick}>
                {/* <span className="settings-icon">🗑️</span> */}
                <span className="settings-icon">⋮</span>
              </div>
              }
            </div>
            : ""
        }
        {
          spacer ? <div className="top-navbar--spacer noselect "></div> : ""
        }
      </div>
    )

    if (showMenuButton) {
      return (
        <div className="top-navbar--menu top-navbar--circle__with-text" onClick={toggleDrawer(true)}>
          <span className="textWebNav">{t(`topnavbar.menu.title`)}</span> &#9776;
        </div>
      )
    } else if (rightButton) {
      return (
        <div>
          <Button
            classes={"top-navbar__button top-navbar__button--save " + (rightButton.color ? "top-navbar__button--color " : "")}
            text={rightButton.text}
            onClick={rightButton.onClick}
          />
        </div>
      )
    } else {
      return (


        <div className="top-navbar--spacer"></div>
      )
    }


  })()

  return (
    <>
      {isLoadingState ? <Loading/> : ""}
      {isLogin && <Login onLogin={() => setIsLogin(false)}
                         onClose={() => setIsLogin(false)}
                         shouldAllowSwitchToRegister={false}/>}
      {isRegister &&
        <Modal
          setIsModalState={() => setIsRegister(false)}
          CONTENT_p={
            <>
              <div className="modal fade-in">
                <Register 
                          onSwitchToLogin={true}
                          shouldAllowSwitchToLogin={true}
                          isSkippable={false}
                          onSignUp={() => setIsRegister(false)}
                          // hasAccout={loginFunc}
                          hasAccout={() => {
                              setIsRegister(false)
                              loginFunc()
                            }}

                />
                

              </div>
            </>
          }
          PRESET_Popup={true}
          isBackground={true}
          isXbutton={true}
          isShowModal={true}
          isModalState={true}
          bottomBorderRadius={false}
          btn1_action={null}
        />
      }
      <div className={"top-navbar noselect " + (showNavBG ? "top-navbar__bg" : "")} style={removeBorder()}>
        {leftSide}
        {center}
        {rightSide}
        <Drawer
          open={navbarState.isMenuOpen}
          anchor={`left`}
          onClose={toggleDrawer(false)}
        >
          <div className="menu">
            <div className="menu__nav noselect">
              <h4>{t(`topnavbar.menu.title`)}</h4>
              <p onClick={toggleDrawer(false)}>{`+`}</p>
            </div>
            <div className="menu__list">
              <div className="menu__list--top">

                <div className="menu__links noselect">
                  <a href="./hiw">How Felt Works</a>
                </div>

                <div className="menu__links noselect">
                  <a href="./faq">Frequently Asked Questions</a>
                </div>


                {/* <div>
                <a href={`mailto:maayan@catbears.com?subject=Feedback on Felt`}>
                  <button className="feedback__button noselect">
                    {t(`topnavbar.menu.feedback_button_text`)}
                  </button>
                </a>
                <p className="micro-text noselect">
                  {t(`topnavbar.menu.feedback_button_micro-text`)}
                </p>
              </div> */}

                {/* <button
                className="change-lang__button language__button texture noselect"
                onClick={() => localeHandler(i18n)}
              >
                {t(`topnavbar.menu.change_language_button_text`)}
              </button> */}

              </div>
              <div className="menu__list--bottom">
                <div className="menu__links noselect">
                  <a
                    href={`https://catbears.com/felt/terms/`}
                    rel={`{noopener noreferer`}
                  >
                    {t(`topnavbar.menu.terms_and_conditions_button_text`)}
                  </a>
                  <a
                    href={`https://catbears.com/felt/privacy/`}
                    rel={`noopener noreferer`}
                  >
                    {t(`topnavbar.menu.privacy_policy_button_text`)}
                  </a>
                  {/* <a href={`/playtime`} rel={`noopener noreferer`}>
                  PlayTime
                </a>
                <a href={`/mytime`} rel={`noopener noreferer`}>
                  MyTime
                </a> */}
                  {!user.isGuest &&
                    <a href={`#`} onClick={() => authContext.logout()}>
                      <div>{t(`topnavbar.menu.logout_button_text`)}</div>
                      <span className="user-email">{user.email}</span>
                    </a>
                  }
                </div>
                <p className="menu__footer noselect">{t(`topnavbar.menu.copyright`)}</p>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default TopNavbar;
