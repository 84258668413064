import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import "./Journey.scss";
import CircleProgress from "../../components/CircleProgress/CircleProgress"
import EmojiToImage from "../../utils/EmojiToImage"
import JourneyFlow from "./JourneyFlow.json"
import * as ANIMALS from "../../components/Animal/AnimalAssets";
import UserContext from "../Login/UserContext";
import SubscriptionModal from "../../components/SubscriptionModal/SubscriptionModal";


function JourneyUI({relationshipID, journeyID, quests, name, partnerName, animalId, partnerAnimalId}) {

  const {t} = useTranslation();
  const {user} = useContext(UserContext);
  const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState(false);

  const partnerAnimal = ANIMALS.default[partnerAnimalId];

  // const getQuestNumber = (quests, questIdx) => quests[questIdx].reduce((memo, step)=> step.isCompleted ? memo++ : memo, 0)
  const getQuestNumber = (quests, questIdx) => {
    var counter = 0;
    quests[questIdx].map(((step) => {
      if (step.isCompleted) {
        counter++
      }
    }))
    return counter
  }

  const getIsJourneyItemCompleted = (journeyItem, index) => {
    return journeyItem.steps.length / getQuestNumber(quests, index) === 1
  }

  const isJourneyItemDisabled = ({index}) => {
    if (index === 0) return false;
    const previousJourneyItem = JourneyFlow.journeyItems[index - 1];
    const isPreviousItemsCompleted = getIsJourneyItemCompleted(previousJourneyItem, index - 1)
    return !isPreviousItemsCompleted;
  }

  const shouldTriggerSubscriptionModal = (index) => {
    // return (index === 2 && !user.isSubscribed) //REMOVING PAYWALL 7.10.2023
  }

  return (
    <>
      <div className="Journey">
        <div className=" CONTENT__list--container CONTENT__list--container--journey">
          {
            JourneyFlow.journeyItems.map(((journeyItem, jIdx) => { // journeyItems is quests
              // quests.map(((quest, questIdx) => {
              console.log("journeyItem " + jIdx + " ", journeyItem)
              return <CircleProgress
                isLocked={isJourneyItemDisabled({journeyItem, index: jIdx})}
                onClick={shouldTriggerSubscriptionModal(jIdx) ? () => setSubscriptionModalOpen(false) : null} 
                key={jIdx}
                textBelowProgressCircle={t(`${journeyItem.title}`, {partnerName: partnerName})}
                insideCircle={EmojiToImage(journeyItem.icon, false)}
                color={"mySide"}
                questNumber={getQuestNumber(quests, jIdx)}
                StepsLength={journeyItem.steps.length}
                ContentInsideProgressCircle={"hi"}
                state={journeyItem}
                pathname={`/relationship/${relationshipID}/journey/${journeyID}/quest/${jIdx}`}
                // isSelected={quest.isSelected}
                isSelected={false}
              />
            }))
          }
          {/* <a className="inviteToFelt-section noselect">
          <div className="inviteToFelt__left">
            <div className="inviteToFelt-section__img"></div>
            <div className="inviteToFelt-section__txt">{'Invite ' + partnerName + ' to join'}</div>
          </div>
          <div className="inviteToFelt_right">
            <div className="inviteToFelt_animal__img"><Animal id={partnerAnimalId} alt={`partner animal`} /></div>
            <div className="inviteToFelt_animal__name">{partnerName}</div>
          </div>
        </a> */}
        </div>
      </div>
      <SubscriptionModal isOpen={isSubscriptionModalOpen} onExit={() => setSubscriptionModalOpen(false)}/> 
    </>
  )
}

export default JourneyUI