import React, {useState, useEffect} from 'react';
import UserContext from './UserContext';
import NewPassword from './NewPassword';
import AuthMenu from './AuthMenu';
import * as AuthState from './authState';
import {changeLanguage} from '../../i18n';
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {CreateGuestUser} from "../../graphql/mutations";

const withAuthentication = (Cmp) => {

  return (props) => {
    const [authProps, setAuthProps] = useState({
      user: null,
      email: '',
      isLoading: false,
      isError: false,
      errorMsg: 'Your email or password are incorrect',
      changeLanguage: null,
      logout: null
    });

    console.log('history.location', window.location);
    const [authState, setAuthState] = useState(AuthState.TOKEN_VALIDATION);

    const [createGuestUserMutation, {data}] = useMutation(gql(CreateGuestUser));

    console.log('athState', authState)

    const verifyToken = () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      const verifyResetReq = async () => {
        const hrefArr = window.location.href.split('/');
        const resetToken = hrefArr[hrefArr.length - 1];
        const rawResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset/${resetToken}`);
        const resp = await rawResponse.json();
        console.log(resp);
        if (resp.ok === false) {
          setAuthState(AuthState.RESET_PASSWORD);
        } else {
          console.log('auth here');
          setAuthState(AuthState.CHANGE_PASSWORD);
        }
      };

      if (window.location.href.includes('/reset')) {
        console.log(window.location.href);
        setAuthState(AuthState.TOKEN_VALIDATION);
        verifyResetReq();
        return;
      }
      if (token && userId) {
        const graphqlQuery = {
          query: `
            {validateToken {
                id
                email
                locale
                isSubscribed
                exitPoll {
                  subscriptionLikelihood
                  reasons
                  explanation
                }
                type
                isGuest
                isOnboardingComplete
              }
            }
          `
        };

        fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(graphqlQuery)
        })
          .then((res) => {
            return res.json();
          })
          .then(async (resp) => {
            if (resp.data.validateToken !== null) {
              setAuthData(resp.data.validateToken);
            } else {
              console.log('auth here');
              await createGuestUser();
            }
          })
          .catch((error) => {
            console.log(error);
            logoutHandler();
          });
      } else {
        console.log('auth here11');
        createGuestUser();
      }
    }

    const saveUserToLocalStorage = (token, userId, shouldExpire) => {
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      if (shouldExpire) {
        const remainingMilliseconds = 60 * 60 * 1000;
        const expiryDate = new Date(new Date().getTime() + remainingMilliseconds);
        localStorage.setItem('expiryDate', expiryDate.toISOString());
      }
    }

    const autheStateHandler = (state, payload) => {
      console.log(state, payload);
      if (state === AuthState.RESET_PASSWORD) {
        setAuthProps({...authProps, email: payload});
      }
      setAuthState(state);
    };

    const createGuestUser = async () => {
      try {
        const result = await createGuestUserMutation();
        const {token, user} = result.data.createGuestUser;
        saveUserToLocalStorage(token, user.id);
        setAuthData(result.data.createGuestUser);
      } catch (error) {
        console.error('Error creating guest user:', error);
      }
    }

    const logoutHandler = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      setAuthProps({
        ...authProps,
        user: null,
        isLoading: false,
        isError: false,
        errorMsg: null
      });
      // verifyToken();
    };

    const handleChangeLanguage = (locale) => {
      console.log('here');
      const token = localStorage.getItem('token');
      const graphqlQuery = {
        query: `
        mutation{changeLocale(
            locale : "${locale}"
        ) {
          id
          email
          locale
        }
      }
        `
      };
      fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(graphqlQuery)
      })
        .then((res) => {
          return res.json();
        })
        .then((resp) => {
          console.log(' changed language resp', resp);
        });
    };

    const changePasswordHandler = (password) => {
      const hrefArr = window.location.href.split('/');
      const token = hrefArr[hrefArr.length - 1];
      setAuthProps({...authProps, isLoading: true});
      const graphqlQuery = {
        query: `
        mutation{
          changePassword(
            password : "${password}", resettoken:"${token}"
            ) {
              token
              id
              email
              locale
            }
          }
        `
      };

      fetch(`${process.env.REACT_APP_BACKEND_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(graphqlQuery)
      })
        .then((res) => {
          return res.json();
        })
        .then((resData) => {
          if (resData.errors && resData.errors[0].status === 422) {
            throw new Error('Login failed. ' + resData.errors[0].data[0].message || '');
          }
          if (resData.errors) {
            console.log('Error!');
            throw new Error(resData.errors[0].message);
          }

          console.log('reset success', resData.data);

          const {token, id} = resData.data.changePassword;
          saveUserToLocalStorage(token, id, true);

          setAuthState(AuthState.DONE_CHANGE_PASSWORD);
          setAuthData(resData.data.changePassword);
          window.location.href = '/';
        });
    };

    useEffect(() => {
      if (authProps.user) return;
      verifyToken();
    }, [authProps]);

    const setAuthData = (authData, isLoading = false) => {
      console.log('setAuthData', authData.login);
      changeLanguage(authData.locale);
      document.body.setAttribute(`lang`, authData.locale);
      setAuthProps({...authProps, isLoading, user: {...authData, ...authData.user}});

      console.log('user....', {...authProps, isLoading, user: {...authData, ...authData.user}});
    };

    const handleUserUpdate = (updatedUser) => {
      setAuthProps({...authProps, user: {...authProps.user, ...updatedUser}});
    }

    if (authProps.user == null)
      return (
        <AuthMenu>
          {(authState === AuthState.CHANGE_PASSWORD ||
            authState === AuthState.DONE_CHANGE_PASSWORD) && (
            <NewPassword
              authProps={authProps}
              setAuthState={autheStateHandler}
              authState={authState}
              changePasswordHandler={changePasswordHandler}
            />
          )}
        </AuthMenu>
      )

    return (
      <UserContext.Provider value={{user: authProps.user, handleUserUpdate, saveUserToLocalStorage, setAuthData}}>
        <Cmp
          authContext={{...authProps, changeLanguage: handleChangeLanguage, logout: logoutHandler}}
        />
      </UserContext.Provider>
    );
  };
};

export default withAuthentication;
