import Dialog from '@material-ui/core/Dialog';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';
import React, { useState } from 'react';
import { graphql, useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import { addMirror, deleteMirror as deleteMirrorMutation } from '../../graphql/mutations';
import { getRelationship } from '../../graphql/queries';
import './Journey.scss';
import JourneyUI from './JourneyUI';

function Journey(props) {
  /// DIALOG -------
  const toggleEditDialog = (open, setIsEditDialogOpen) => (event) => {
    setIsEditDialogOpen({ open });
  };
  const historyHook = useHistory();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  // DIALOG END --------
  const { t } = useTranslation();
  const { match } = props;
  const { params } = match;
  const { id, journeyID } = params;
  const relationshipID = id;
  const [deleteMirror] = useMutation(gql(deleteMirrorMutation), {
    refetchQueries: [{ query: gql(getRelationship), variables: { id: relationshipID } }]
  });
  const { loading, error, data } = useQuery(gql(getRelationship), {
    variables: { id: match.params.id },
    fetchPolicy: 'network-only'
  });

  if (loading) return <Loading />;
  const { mySideMirrors, name, partnerName, animalId, partnerAnimalId, relation, gender, partnerGender } = data.getRelationship;
  const mySideMirror = mySideMirrors.find((m) => m.id === journeyID);
  const quests = mySideMirror.progress.flowState;

  console.log("partnerAnimalId", partnerAnimalId)

  return (
    <>
      <div className="CONTENT__container ">
        <Dialog
          // title={t('mirroring.edit-relationship')}
          open={isEditDialogOpen}
          onClose={toggleEditDialog(false, setIsEditDialogOpen)}
        >
          <div className="modal-buttons">
              {/* <Button
                classes="relationship-page__settings-delete-button "
                text={t('summary.viewSummary')}
                isLink={true}
                to={(`/relationship/${relationshipID}/journey/${journeyID}/summary`)}
              /> */}
            <div className="modal-buttons__delete">
              <Button
                classes="relationship-page__settings-delete-button "
                text={t(`mirroring.edit.delete`)}
                onClick={async () => {
                  const confirmDelete = window.prompt("Type 'Delete' to confirm the deletion:");
                
                  if (confirmDelete === "Delete") {
                    await deleteMirror({ variables: { input: journeyID } });
                    historyHook.push(`/relationship/${relationshipID}`);
                  }
                }}
              />
            </div>
            <p className="micro-text micro-text__white">
              {t(`mirroring.edit.delete-warning`)}
            </p>
            <Button
              className="close"
              text={t(`general.close`)}
              onClick={() => setIsEditDialogOpen(false)}
              // onClick={toggleDialog(false, setIsEditDialogOpen)}
            />
          </div>
        </Dialog>
        <SideBarPanelNav
          left1={'backButton'}
          // left2={'summaryButton'}
          summaryURL={(`/relationship/${relationshipID}/journey/${journeyID}/summary`)}
          rightAction={() => setIsEditDialogOpen(true)}
          center={'twoLinesTopSubTitle'}
          centerTitle={mySideMirror.situationName || t(`mirroring.defaultJourneyName`)}
          // centerSubTitle={t(`mirroring.defaultJourneyType`)}
          centerSubTitle={t(`homepage.meAndPartner`, {
            name: name,
            partnerName: partnerName
          })}
          // right={'settingsButton'}
          right={'summaryButton'}
          leftTo={`/relationship/${params.id}`}
        />
        <JourneyUI
          relationshipID={id}
          journeyID={journeyID}
          quests={quests}
          name={name}
          partnerName={partnerName}
          animalId = {animalId}
          partnerAnimalId = {partnerAnimalId}
        />
      </div>
    </>
  );
}

export default compose(
  graphql(gql(addMirror), {
    options: (props) => ({
      refetchQueries: [{ query: gql(getRelationship) }],
      awaitRefetchQueries: false
    })
  })
)(Journey);
