/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserByEmailQuery = `
  query GetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      email
      isSubscribed
      createdAt
      updatedAt
      locale
      registered
      type
      exitPoll {
        reasons
        subscriptionLikelihood
        explanation
      }
      relationships {
        id
        createdAt
        updatedAt
        name
        animalId
        gender
        partnerAnimalId
        partnerGender
        relation
        partnerName
        mySideMirrors {
          id
          whatHappened
        }
      }
    }
  }
`;

export const getUser = /* GraphQL */ `
  query {
    user {
      id
      username
      email
      registered
      locale
      isSubscribed
      type
      isGuest
      isOnboardingComplete
    }
  }
`;

export const getRelationship = /* GraphQL */ `
  query GetRelationship($id: String) {
    getRelationship(id: $id) {
      id
      createdAt
      updatedAt
      name
      animalId
      gender
      partnerAnimalId
      partnerGender
      relation
      partnerName  
      mySideMirrors{
        id
        relationshipId
        progress{
          journeyItem
          step
          version
          currentStepNumber
          flowState{
            isCompleted
          }
        }
        createdAt
        updatedAt
        situationName
        whatHappened
        feelTowardsPartner {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        whyFeelTowardsPartner
        feelTowardsSituation {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        whyFeelTowardsSituation
        whatYouNeed {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        whyYouNeed
        whatHope
        whyHope
        believeItCanWork
        myTriggerUnpleasant {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        myTriggerInsteadUnpleasant {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        myTriggerHappenMore {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        myTriggerSummary
        assumeWhatHappened 
        assumeFeelTowardsSituation{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumeWhyFeelTowardsSituation
        assumeFeelTowardsMe{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumeWhyFeelTowardsMe
        assumeWhyPartnerNeed
        assumeWhatPartnerNeed{
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumePartnerTriggerUnpleasant {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumePartnerTriggerInsteadUnpleasant {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumePartnerTriggerHappenMore {
          cardType
          isReversed
          isReduced
          prefix
          modifier
          emoji
          isPositive
          title
          description
          switchSides
        }
        assumePartnerTriggerSummary 

      } 
    }
  }
`;

export const getMySideMirror = `
  query GetMirror($id: String) {
    getMirror(id: $id) {
      id
      relationshipId
      progress{
        journeyItem
        step
        version
        currentStepNumber
        flowState{
          isCompleted
        }
      }
      createdAt
      updatedAt
      situationName
      whatHappened
      feelTowardsPartner {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      whyFeelTowardsPartner
      feelTowardsSituation {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      whyFeelTowardsSituation
      whatYouNeed {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      whyYouNeed
      whatHope
      whyHope
      believeItCanWork
      myTriggerUnpleasant {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      myTriggerInsteadUnpleasant {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      myTriggerHappenMore {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      myTriggerSummary
      assumeWhatHappened 
      assumeFeelTowardsSituation{
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      assumeWhyFeelTowardsSituation
      assumeFeelTowardsMe{
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      assumeWhyFeelTowardsMe
      assumeWhyPartnerNeed
      assumeWhatPartnerNeed{
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      assumePartnerTriggerUnpleasant {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      assumePartnerTriggerInsteadUnpleasant {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      assumePartnerTriggerHappenMore {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      assumePartnerTriggerSummary 

    } 
  }
`;

export const mySideMirror = `
mySideMirrors {
  id
  createdAt
  updatedAt
  situationName
  whatHappened
  feelTowardsPartner {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyFeelTowardsPartner
  feelTowardsSituation {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyFeelTowardsSituation
  whatYouNeed {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  whyYouNeed
  whatHope
  whyHope
  believeItCanWork
  myTriggerUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerInsteadUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerHappenMore {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  myTriggerSummary
  partnerTriggerUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  partnerTriggerInsteadUnpleasant {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  partnerTriggerHappenMore {
    cardType
    isReversed
    isReduced
    prefix
    modifier
    emoji
    isPositive
    title
    description
    switchSides
  }
  partnerTriggerSummary
}
`



export const apiListRelationships = `
  query listRelationships{
    listRelationships{
      relationships{
        id
        createdAt
        updatedAt
        name
        animalId
        gender
        partnerAnimalId
        partnerGender
        relation
        partnerName
      }
    }
  }
`
export const listRelationships = /* GraphQL */ `
  query ListRelationships(
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelationships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        animalId
        gender
        partnerAnimalId
        partnerGender
        relation
        partnerName
        owner {
          id
          username
          email
          registered
          locale
          relationships {
            items {
              id
              createdAt
              updatedAt
              name
              animalId
              gender
              partnerAnimalId
              partnerGender
              relation
              partnerName
              owner {
                id
                username
                email
                registered
                locale
                createdAt
                updatedAt
              }
              mySideMirrors {
                id
                createdAt
                updatedAt
                situationName
                whatHappened
                whyFeelTowardsPartner
                whyFeelTowardsSituation
                whyYouNeed
                whatHope
                whyHope
                believeItCanWork
                myTriggerSummary
                partnerTriggerSummary
              }
            }
            nextToken
          }
          playlists {
            items {
              id
              createdAt
              owner {
                id
                username
                email
                registered
                locale
                createdAt
                updatedAt
              }
              videos {
                url
                start
                end
                title
              }
              name
              order
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        partnerRelationshipSurvey {
          surveyFeeling {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyHappened {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyNeeds {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
        }
        partnerTriggerSurvey {
          surveyFeeling {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyHappened {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyNeeds {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
        }
        relationshipSurvey {
          surveyFeeling {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyHappened {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyNeeds {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
        }
        triggerSurvey {
          surveyFeeling {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyHappened {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          surveyNeeds {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
        }
        mySideMirrors {
          id
          createdAt
          updatedAt
          situationName
          whatHappened
          feelTowardsPartner {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          whyFeelTowardsPartner
          feelTowardsSituation {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          whyFeelTowardsSituation
          whatYouNeed {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          whyYouNeed
          whatHope
          whyHope
          believeItCanWork
          myTriggerUnpleasant {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          myTriggerInsteadUnpleasant {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          myTriggerHappenMore {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          myTriggerSummary
          partnerTriggerUnpleasant {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          partnerTriggerInsteadUnpleasant {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          partnerTriggerHappenMore {
            cardType
            isReversed
            isReduced
            prefix
            modifier
            emoji
            isPositive
            title
            description
            switchSides
          }
          partnerTriggerSummary
        }
      }
      nextToken
    }
  }
`;
export const getPlaylist = /* GraphQL */ `
  query GetPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      id
      createdAt
      owner {
        id
        username
        email
        registered
        locale
        relationships {
          items {
            id
            createdAt
            updatedAt
            name
            animalId
            gender
            partnerAnimalId
            partnerGender
            relation
            partnerName
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            partnerRelationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            partnerTriggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            relationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            triggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            mySideMirrors {
              id
              createdAt
              updatedAt
              situationName
              whatHappened
              feelTowardsPartner {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyFeelTowardsPartner
              feelTowardsSituation {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyFeelTowardsSituation
              whatYouNeed {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              whyYouNeed
              whatHope
              whyHope
              believeItCanWork
              myTriggerUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerInsteadUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerHappenMore {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              myTriggerSummary
              partnerTriggerUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerInsteadUnpleasant {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerHappenMore {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              partnerTriggerSummary
            }
          }
          nextToken
        }
        playlists {
          items {
            id
            createdAt
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            videos {
              url
              start
              end
              title
            }
            name
            order
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videos {
        url
        start
        end
        title
      }
      name
      order
      updatedAt
    }
  }
`;
export const listPlaylists = /* GraphQL */ `
  query ListPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        owner {
          id
          username
          email
          registered
          locale
          relationships {
            items {
              id
              createdAt
              updatedAt
              name
              animalId
              gender
              partnerAnimalId
              partnerGender
              relation
              partnerName
              owner {
                id
                username
                email
                registered
                locale
                createdAt
                updatedAt
              }
              mySideMirrors {
                id
                createdAt
                updatedAt
                situationName
                whatHappened
                whyFeelTowardsPartner
                whyFeelTowardsSituation
                whyYouNeed
                whatHope
                whyHope
                believeItCanWork
                myTriggerSummary
                partnerTriggerSummary
              }
            }
            nextToken
          }
          playlists {
            items {
              id
              createdAt
              owner {
                id
                username
                email
                registered
                locale
                createdAt
                updatedAt
              }
              videos {
                url
                start
                end
                title
              }
              name
              order
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        videos {
          url
          start
          end
          title
        }
        name
        order
        updatedAt
      }
      nextToken
    }
  }
`;
