import React from 'react';
import './Welcome.scss';
// import Button from "./../Button/Button";
import { Link } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import TopNavbar from "../../components/TopNavbar/TopNavbar";
import FeltLogo from "../../components/FeltLogo/FeltLogo";
import ProfileImgName from "../../components/ProfileImgName/ProfileImgName";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { listRelationships } from "../../graphql/queries";


function Welcome({ data: { listRelationships }, user }) {
    const relationships = listRelationships ? listRelationships.items : [];
    const {t, i18n} = useTranslation();


    return (
        <div className="LAYOUT-pageContainer LAYOUT-fullscreen LAYOUT-TopPadding background-skyblue">
            <div className="welcome-container">
                <div className="welcome-top">
                
                    <FeltLogo
                        color="white"
                        size="large"
                    />
                    
                    <h1 className="color-white">Making relationships work</h1>
                </div>
                
                <div className="welcome-center">
                <Link
                
                    to={{
                    pathname: `/`,
                    // state: {id: relationship.id }
                    //   state: { relationships[0] } // LIOR: what's this state being passed to <Link/> ?  
                    }}
                >
                <ProfileImgName 
                    name={t('general.guest')}
                    img="guest-img.png"
                />
              </Link>
                  
                </div>
                <div className="welcome-bottom color-white">
                  Felt by The Catbears - מעין מלכה © 2023
                </div>
            </div>
        </div>
    )
}

export default graphql(gql(listRelationships), {
  options: {
    variables: { limit: 50 },
    fetchPolicy: "network-only"
  }
})(Welcome);