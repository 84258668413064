import React from 'react';
import Animal from '../Animal/Animal';
import "./Stage.scss";
import * as ANIMALS from "../Animal/AnimalAssets";
import { useTranslation } from "react-i18next";


function Stage({ children, name, partnerName, background, animalId, partnerAnimalId, classes, showSides, mySide_distance, partnerSide_distance }) {
  const { t } = useTranslation();
  const animal = ANIMALS.default[animalId];
  const partnerAnimal = ANIMALS.default[partnerAnimalId];
  
  // REMINDER - would love to calc the stage width and set the distances precentage according to the stage width and not the screen width

  document.documentElement.style.setProperty("--mySide_distance", mySide_distance);
  document.documentElement.style.setProperty("--partnerSide_distance", partnerSide_distance);

  return (
      <div className={"stage--container  noselect " + classes }>
        <div className={"stage " }>
          {/* {children} */}
          <div className="side mySide">
            <Animal id={animalId} alt={`my animal`} />
            <div className="stage__name stage__name--mySide">{name}</div>
            {showSides ? <div className="stage__name stage__name--mySide stage__name--relation">{t(`create_new_relationship.step1.title`)}</div> : ""}
          </div> 
          <div className="side partnerSide">
            <Animal id={partnerAnimalId} alt={`partner animal`} />
            <div className="stage__name stage__name--partnerSide">{partnerName}</div>
            {showSides ? <div className="stage__name stage__name--mySide stage__name--relation">{t(`create_new_relationship.step2.title`)}</div> : ""}
          </div>
          
        </div>
      </div>
      
      
  )
}    

export default Stage;