import React from 'react';
import './Step.scss';
import FlowStep from '../FlowStep/FlowStep';

function Step({
  stepNumber,
  questData,
  stepContext,
  isSelected,
  title,
  stepItemsFlow,
  name,
  partnerName,
  gender,
  partnerGender,
  send,
  saveQuestData,
  questFormRef
}) {
  const { isMandatory, isCompleted } = stepContext;

  return (
    <>
      <div
        className={
          'Step ' +
          (isCompleted ? 'stepCompleted ' : '') +
          (isSelected ? 'stepSelected ' : 'displayNone')
        }
      >
        {/* <div className="stepNav-testing">
          <p className="stepNumber">Step {stepNumber}</p>
          <p>
            <span>isCompleted </span>{' '}
            <span className={isCompleted ? 'isTrue' : ''}>{isCompleted ? 'true' : 'false'}</span>
          </p>
          <p>
            <span>isMandatory </span>
            <span className={isMandatory ? 'isTrue' : ''}>{isMandatory ? 'true' : 'false'}</span>
          </p>
        </div> */}

        {/* <h1>{title}</h1> */}
        <form className="form" onSubmit={(event) => event.preventDefault()}>
          {stepItemsFlow.map((stepItemFlow, itemIdx) => {
            return (
              <div className="stepItem " key={'stepItem' + itemIdx}>
                
                <FlowStep
                  stepItemFlow={stepItemFlow}
                  stepItemData={questData[stepItemFlow.state]}
                  stepNumber={stepNumber}
                  name={name}
                  partnerName={partnerName}
                  gender={gender}
                  partnerGender={partnerGender}
                  questFormRef={questFormRef}
                  updateValue={(value) => {
                    const res = send({ type: 'UPDATE_STEP', value, stepItemFlow });
                    const {
                      context: { journey }
                    } = res;
                    saveQuestData(journey);
                  }}
                />
              </div>
            );
          })}
        </form>
      </div>
    </>
  );
}

Step = React.memo(Step);
export default Step;
