import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {login} from "../../graphql/mutations";
import Register from "./Register";
import Modal from "../../components/Modal/Modal";
import UserContext from "./UserContext";
import RestorePassword from "./RestorePassword";


const Login = ({onLogin, onClose, shouldAllowSwitchToRegister, getIsRegisterMode=false}) => {
  const {user, handleUserUpdate, saveUserToLocalStorage, setAuthData} = useContext(UserContext)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegisterMode, setIsRegisterMode] = useState(getIsRegisterMode);
  const [isResetPassword, setIsResetPassword] = useState(false);

  const {t} = useTranslation();

  const [loginMutation, {loading}] = useMutation(gql(login))

  const _onLogin = async (event) => {
    event.preventDefault();
    try {
      const result = await loginMutation({variables: {email, password}})
      const resultData = result.data.login;
      const {token, user} = resultData
      saveUserToLocalStorage(token, user.id);
      setAuthData(resultData);
      onLogin({email, password})

    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  }

  const LoginElements = () => {
    return <>
      <h2 className="modal__title">{t("loginFlow.logIn")}</h2>
      <form onSubmit={_onLogin} className="modal__form">
        <div className={"modal__form--error-msg " + (error ? "" : " displayNone ")}>
          {error}
        </div>
        <div>
          <label className={"form-label noselect "}>
            {t("loginFlow.email")}
          </label>
          <input type="email"
                 className="input input-authMenu"
                 value={email}
                 onChange={e => setEmail(e.target.value)}/>
        </div>
        <div>
          <label className={"form-label noselect "}>
            {t("loginFlow.password")}
          </label>
          <input type="Password" className="input input-authMenu" value={password}
                 onChange={e => setPassword(e.target.value)}/>
        </div>
        <button className={"modal__form--sumbit-button " + (loading ? "preloaderGif " : " ")}
                type="submit">{loading ? "" : t("loginFlow.logInBtn")}
        </button> 
      </form>
      <div className="modal__below-submit ">
        {shouldAllowSwitchToRegister && <div className="createAccount-container">
          <p>{t("loginFlow.FirstTimeHere") + " "}
            <a className="modal__below-submit__btn modal__below-submit__btn--create-account"
               onClick={() => setIsRegisterMode(true)}>
              {t("loginFlow.createAnAccount")}
            </a>
          </p>
        </div>}
        <a className="noselect link_no-button register_mini-text register_login-link " onClick={() => setIsResetPassword(true)}>
          {t("loginFlow.forgotPassword")}
        </a>
        <div className="noselect modal__below-submit--micro-text ">{t("loginFlow.byLogging") + " "}
          <a className="noselect link_no-button register_mini-text register_login-link ">{t("loginFlow.privacyPolicy")}</a>
          {" " + t("loginFlow.and")}
          <a className="noselect link_no-button register_mini-text register_login-link ">{t("loginFlow.TermsOfService")}</a>
          {" " + t("loginFlow.endOfSentence")}.
        </div>
      </div>
    </>
  }

  const renderContent = () => {
    if (isRegisterMode)
      return <Register onSwitchToLogin={() => setIsRegisterMode(false)} shouldAllowSwitchToLogin={true}/>

    else if (isResetPassword)
      return <RestorePassword onSwitchToLogin={() => setIsResetPassword(false)} initialEmail={email}/>

    return <LoginElements/>
  }

  return (
    <Modal
      setIsModalState={() => onClose()}
      CONTENT_p={
        <>
          <div className="modal fade-in">
            {renderContent()}
          </div>
        </>
      }
      PRESET_Popup={true}
      isBackground={true}
      isXbutton={true}
      isShowModal={true}
      isModalState={true}
      bottomBorderRadius={false}
      btn1_action={null}
    />

  )
}


export default Login;