import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardEditor from '../../components/CardEditor/CardEditor';
import CardWrap from '../../components/CardGallery/CardWrap';
import Meter from '../../components/Meter/Meter';
import CardKeyboard from '../../components/CardKeyboard/CardKeyboard';
import Intro from '../../components/Intro/Intro';
import TextArea from '../../components/TextArea/TextArea';
import { feelCards, happenedCards, needCards } from '../../static/cards';

function FlowStep({
  stepItemFlow,
  stepItemData,
  name,
  partnerName,
  gender,
  partnerGender,
  updateValue,
  questFormRef
}) {
  const [keyboardState, setKeyboardState] = useState({ isOpen: false });
  const [cardEditorState, setCardEditorState] = useState({ isOpen: false });
  const cardGalleryRef = useRef();

  const { t } = useTranslation();

  const handleBlur = (e) => {
    e.preventDefault();
  };

  const toggleDrawer = (open, setKeyboardState) => (event) => {
    setKeyboardState({ isOpen: open });
  };

  const toggleDialog = (open, setCardEditorState) => (event) => {
    setCardEditorState({ isOpen: open });
  };

  const openKeyboard = ({ cards, filters, onSelect, element, switchSides }) => {
    setKeyboardState({
      isOpen: true,
      cards,
      name,
      partnerName,
      gender,
      partnerGender,
      filters,
      onSelect,
      switchSides
    });
  };

  const CardEditorMap = {
    feelTowardsPartner: { FEEL: 'feelTowardsPartner' },
    feelTowardsSituation: { FEEL: 'feelTowardsSituation' },
    whatYouNeed: { FEEL: 'whatYouNeed' },
    assumeFeelTowardsSituation: { FEEL: 'assumeFeelTowardsSituation' },
    assumeFeelTowardsMe: { FEEL: 'assumeFeelTowardsMe' },
    assumeWhatPartnerNeed: { FEEL: 'assumeWhatPartnerNeed' },
    assumePartnerTriggerUnpleasant: { FEEL: 'assumePartnerTriggerUnpleasant' },
    AssumePartnerTriggerInsteadUnpleasant: { FEEL: 'AssumePartnerTriggerInsteadUnpleasant' },
    AssumePartnerTriggerHappenMore: { FEEL: 'AssumePartnerTriggerHappenMore' },
    myTriggerUnpleasant: { HAPPENED: 'myTriggerUnpleasant' },
    myTriggerInsteadUnpleasant: { HAPPENED: 'myTriggerInsteadUnpleasant' },
    myTriggerHappenMore: { HAPPENED: 'myTriggerHappenMore' }
  };

  const openCardEditor = ({ card, index: cardIndex, cardWrapType }) => {
    setCardEditorState({
      isOpen: true,
      card,
      name,
      partnerName,
      gender,
      partnerGender,
      onChange: ({ card }) => {
        const currentStepItemData = [...stepItemData];
        currentStepItemData[cardIndex] = card;
        updateValue(currentStepItemData);
      },
      onDelete: () => {
        const updatedCards = stepItemData.filter((e, idx) => idx !== cardIndex);
        setCardEditorState({ isOpen: false });
        updateValue(updatedCards);
      },
      onClose: () => setCardEditorState({ isOpen: false })
    });
  };

  // set isCompleted according to logic

  const getStepItemInput = (stepItemFlow) => {
    if (!stepItemFlow) return;

    switch (stepItemFlow.type) {
      // case 'Crossroad':
      //   return (
      //     <>
      //     <Intro
      //       insideCircle={stepItemFlow.icon}
      //       side={stepItemFlow.side}
      //       title={t(stepItemFlow.title, {
      //         name: name,
      //         partnerName: partnerName
      //       })}
      //       subtitle={t(stepItemFlow.subtitle, {
      //         name: name,
      //         partnerName: partnerName
      //       })}
      //       description={t(stepItemFlow.description, {
      //         name: name,
      //         partnerName: partnerName
      //       })}
      //     />
      //     <div>{t(stepItemFlow.title, {
      //         name: name,
      //         partnerName: partnerName
      //       })}</div>
      //     <div>{t(stepItemFlow.description, {
      //         name: name,
      //         partnerName: partnerName
      //       })}
            
      //       </div>
      //       <div>Take a few deep breaths cand calm down. If you're not feeling great, maybe it's a good idea to take a break, and go over your mirror after some time.</div>
      //       <div>If you think you can improve your relationship, you might want to talk to ____ about your mirror.</div>
      //       <div>If you feel like it's a good idea, you can also invite _____ to createt their own mirror your relationship</div>
            
      //       <p>* Don't worry, they won't be able to see any of the information you've created</p>
      //       </>
      //   );
      case 'Intro':
        return (
          <Intro
            insideCircle={stepItemFlow.icon}
            side={stepItemFlow.side}
            title={t(stepItemFlow.title, {
              name: name,
              partnerName: partnerName
            })}
            subtitle={t(stepItemFlow.subtitle, {
              name: name,
              partnerName: partnerName
            })}
            p1={t(stepItemFlow.p1, {
              name: name,
              partnerName: partnerName
            })}
            p2={t(stepItemFlow.p2, {
              name: name,
              partnerName: partnerName
            })}
            p3={t(stepItemFlow.p3, {
              name: name,
              partnerName: partnerName
            })}
            description={t(stepItemFlow.description, {
              name: name,
              partnerName: partnerName
            })}
            link={t(stepItemFlow.link, {
              name: name,
              partnerName: partnerName
            })}
            linkTxt={t(stepItemFlow.linkTxt, {
              name: name,
              partnerName: partnerName
            })}

          />
        );
      case 'InviteEmail':
        return (
          <>
            <div className="InviteEmail">
              <div className="InviteEmail_title">
                <h2>{'Invite ' + partnerName + ' to Felt'}</h2>
                <h3>
                  {'Send this message to ' +
                    partnerName +
                    ' and invite her to map your relationship with Felt'}
                </h3>
              </div>
              <div className="message-preview">
                <p className="message-preview_subject">{partnerName + ', join me on Flet'}</p>
                <div className="message-preview_message">
                  <p>{'Hi ' + partnerName + ','}</p>
                  <p>
                    I've mapped our relationship using a new app called Felt. It helped me
                    understand what I think has happened between us, and to think about your side of
                    the story.
                  </p>
                  <p>
                    It would really help us understand each other if you also use Felt to map our
                    relationship from your point of view.
                  </p>
                  <p>
                    It's really simple to use. just follow this link:{' '}
                    <a href="felt.catbears.com">felt.catbears.com</a>
                  </p>
                  <p>Once you finish we can talk. (I won't be able to see anything you do there)</p>
                  <p>Thanks, and let me when you're ready.</p>
                  <p>{name}</p>
                </div>
              </div>
              <p className="privacy-reminder">
                {"Don't worry. " +
                  partnerName +
                  " won't have access to the relationship you've created in Felt"}
              </p>
              <a href="https://api.whatsapp.com/send?text=Send20%a20%quote">Send Messageeee</a>

              {/* <a href={"mailto:?subject=" + partnerName +", join me on Flet"}>Invite them to Felt</a> */}
            </div>
          </>
          // <Intro
          //   insideCircle={stepItemFlow.icon}
          //   side={stepItemFlow.side}
          //   title={t(stepItemFlow.title, {
          //     name: name,
          //     partnerName: partnerName
          //   })}
          //   subtitle={t(stepItemFlow.subtitle, {
          //     name: name,
          //     partnerName: partnerName
          //   })}
          //   description={t(stepItemFlow.description, {
          //     name: name,
          //     partnerName: partnerName
          //   })}
          // />
        );
      case 'TextArea':
        return (
          <TextArea
            key={stepItemFlow.key}
            label={t(stepItemFlow.label, {
              name: name,
              partnerName: partnerName
            })}
            isMandatory={stepItemFlow.mandatory}
            rows={stepItemFlow.rows}
            value={stepItemData}
            id={stepItemFlow.id}
            name={stepItemFlow.name}
            isStepItemMandatory={stepItemFlow.mandatory}
            onChangeTextArea={updateValue}
            handleBlur={handleBlur}
          />
        );
      case 'FeelsCard':
        return (
          <>
            <label className="form-label ">
              {t(stepItemFlow.label, {
                name: name,
                partnerName: partnerName
              })}
            </label>
            <CardWrap
              openKeyboard={openKeyboard}
              openCardEditor={openCardEditor}
              cards={feelCards}
              selectedCards={stepItemData || []}
              name={name}
              partnerName={partnerName}
              cardGalleryRef={cardGalleryRef}
              questFormRef={questFormRef}
              gender={gender}
              partnerGender={partnerGender}
              cardWrapType={stepItemFlow.cardWrapType}
              onCardChange={updateValue}
            />
          </>
        );
      case 'HappenedCard': {
        return (
          <>
            <label className="form-label ">
              {t(stepItemFlow.label, {
                name: name,
                partnerName: partnerName
              })}
            </label>
            <CardWrap
              openKeyboard={openKeyboard}
              openCardEditor={openCardEditor}
              cards={happenedCards}
              selectedCards={stepItemData || []}
              length={stepItemFlow.length}
              name={name}
              partnerName={partnerName}
              gender={gender}
              partnerGender={partnerGender}
              questFormRef={questFormRef}
              cardGalleryRef={cardGalleryRef}
              cardWrapType={stepItemFlow.cardWrapType}
              onCardChange={updateValue}
            />
          </>
        );
      }
      case 'NeedCard':
        return (
          <>
            <label className="form-label ">
              {t(stepItemFlow.label, {
                name: name,
                partnerName: partnerName
              })}
            </label>
            <CardWrap
              openKeyboard={openKeyboard}
              openCardEditor={openCardEditor}
              cards={needCards}
              selectedCards={stepItemData || []}
              name={name}
              partnerName={partnerName}
              gender={gender}
              partnerGender={partnerGender}
              questFormRef={questFormRef}
              cardGalleryRef={cardGalleryRef}
              cardWrapType={stepItemFlow.cardWrapType}
              onCardChange={updateValue}
            />
          </>
        );
        case 'Meter': {
          return (
            <>
              <label className="form-label ">
                {t(stepItemFlow.title, {
                  name: name,
                  partnerName: partnerName
                })}
              </label>
              <Meter 
                name={name}
                partnerName={partnerName}
                gender={gender}
                partnerGender={partnerGender}
                meterType={'regular'}
                meterItems={stepItemFlow.meterItems}
              />
            </>
          );
        }
      default:
        return null;
    }
  };

  return (
    <>
      {getStepItemInput(stepItemFlow)}
      <Drawer
        open={keyboardState.isOpen}
        anchor={`bottom`}
        onClose={toggleDrawer(false, setKeyboardState)}
      >
        {keyboardState.isOpen && (
          <CardKeyboard
            {...keyboardState}
            filters={[
              { title: t(`card_keyboard.all`), filterFn: () => true },
              { title: t(`card_keyboard.positive`), filterFn: (card) => card.isPositive },
              { title: t(`card_keyboard.negative`), filterFn: (card) => !card.isPositive },
              { title: '✕', filterFn: toggleDrawer(false, setKeyboardState) }
            ]}
          />
        )}
      </Drawer>
      <Dialog open={cardEditorState.isOpen} onClose={toggleDialog(false, setCardEditorState)}>
        {cardEditorState.isOpen && <CardEditor {...cardEditorState} />}
      </Dialog>
    </>
  );
}
FlowStep = React.memo(FlowStep, (prevProps, nextProps) => {
  if (prevProps.stepItemData !== nextProps.stepItemData) {
    return false;
  }
  return true;
});
export default FlowStep;
