import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from "react-apollo";
import {mailResetPassword} from "../../graphql/mutations";
import gql from "graphql-tag";


const RestorePassword = ({onSwitchToLogin, initialEmail = ''}) => {

  const [sendEmailMutation, {data, error, loading}] = useMutation(gql(mailResetPassword));

  const [email, setEmail] = useState(initialEmail);
  const [sentEMail, setSentEMail] = useState(false);

  const {t} = useTranslation();

  const onLogin = async (event) => {
    event.preventDefault();
    try {
      await sendEmailMutation({variables: {email}});
      setSentEMail(true);
    } catch (error) {
      console.log('error sending email:', error);
    }
  };

  return (
    <div>
      {sentEMail ? (
        <> 
          <div className="popup popup_center popup-dark-txt">
            <img src="/felt_check-your-inbox.PNG"/>
            <h1 className="txtCenter">{t('modal.reset-password.title')}</h1>
            <p className="txtCenter">{t('modal.reset-password.subtitle')}</p>
            <a href="/">
              <button className="button">{t("modal.reset-password.backToLogin")}</button>
            </a>
          </div>
        </>
      ) : (
        <div className="modal fade-in">
          <h2 className="modal__title">{t("loginFlow.resetPassword")}</h2>
          <form onSubmit={onLogin} className="modal__form">
            <div className={'modal__form--error-msg ' + (error ? '' : ' displayNone ')}>
              {error?.message}
            </div>
            <div>
              <label className={'form-label noselect '}>{t("loginFlow.email")}</label>
              <input
                type="email"
                className="input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>

            <button
              className={'modal__form--sumbit-button ' + (loading ? 'preloaderGif ' : ' ')}
              type="submit">
              {loading ? '' : t("loginFlow.sendEmail")}
            </button>
          </form>
          <div className="modal__below-submit">
            <p>
              {t("loginFlow.rememberPassword")}&nbsp;
              <a className="noselect link_no-button register_mini-text register_login-link "
                 onClick={onSwitchToLogin}>
                {t("loginFlow.logInBtn")}
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestorePassword;
