import omitDeep from 'omit-deep-lodash';

const happenedCardsRaw = [
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🤔',
    title: "I don't know",
    titleHe:  'אני לא יודע',
    prefix: '',
    prefixHe: '',
    title_male: "I don't know",
    title_female: "I don't know",
    titleHe_male: 'אני לא יודע',
    titleHe_female: 'אני לא יודעת',
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    description: '',
    emoji: '🙊',
    title: "I don't want to share",
    prefix: '',
    title_male: "I don't want to share",
    title_female: "I don't want to share",
    titleHe_male: 'לא בא לי לשתף',
    titleHe_female: 'לא בא לי לשתף',
    descriptionHe: 'לא בא לי לשתף',
    prefixHe: '',
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    description: '',
    emoji: '😶',
    title: 'Nothing',
    prefix: '',
    title_male: 'Nothing',
    title_female: 'Nothing',
    titleHe_male: 'לא עושה כלום',
    titleHe_female: 'לא עושה כלום',
    descriptionHe: 'לא עושה כלום',
    prefixHe: 'ל'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    description: 'A profoundly affection for another person.',
    emoji: '❤️',
    prefix: 'to',
    prefixHe: 'ל',
    title: 'Show love',
    titleHe: 'מראה אהבה',
    title_male: 'Show love',
    title_female: 'Show love',
    titleHe_male: 'מראה אהבה',
    titleHe_female: 'מראה אהבהת',
    descriptionHe: 'מראה אהבה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '👏',
    prefix: '',
    prefixHe: 'את',
    title: 'Cheers',
    titleHe: 'מעודד',
    title_male: 'Cheers',
    title_female: 'Cheers',
    titleHe_male: 'מעודד',
    titleHe_female: 'מעודדת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: ' 😊',
    title: 'Forgave',
    titleHe: 'סולח',
    prefix: '',
    prefixHe: 'ל',
    title_male: 'Forgave',
    title_female: 'Forgave',
    titleHe_male: 'סולח',
    titleHe_female: 'סולחת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '🛡️',
    title: 'Protects',
    titleHe: 'שומר',
    prefix: ' ',
    prefixHe: 'על',
    title_male: 'Protects',
    title_female: 'Protects',
    titleHe_male: 'שומר',
    titleHe_female: 'שומרת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '😊',
    title: 'Show affection',
    titleHe: 'מראה חיבה',
    prefix: 'to',
    prefixHe: 'ל',
    title_male: 'Show affection',
    title_female: 'Show affection',
    titleHe_male: 'מראה חיבה',
    titleHe_female: 'מראה חיבה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '😂',
    title: 'Laughs',
    titleHe: 'צוחק',
    prefix: 'with',
    prefixHe: 'עם',
    title_male: 'Laughs',
    title_female: 'Laughs',
    titleHe_male: 'צוחק',
    titleHe_female: 'צוחקת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '👹',
    title: 'Mocks',
    titleHe: 'לועג',
    prefix: '',
    prefixHe: 'ל',
    title_male: 'Mocks',
    title_female: 'Mocks',
    titleHe_male: 'לועג',
    titleHe_female: 'לועגת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '🤗',
    title: 'Hugs',
    prefix: '',
    titleHe: 'מחבק',
    prefixHe: 'את',
    title_male: 'Hugs',
    title_female: 'Hugs',
    titleHe_male: 'מחבק',
    titleHe_female: 'מחבקת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '😌',
    title: 'Polite',
    prefix: 'to',
    titleHe: 'מנומס',
    prefixHe: 'כלפי',
    title_male: 'Respects',
    title_female: 'Respects',
    titleHe_male: 'מנומס',
    titleHe_female: 'מנומסת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '👂',
    title: 'Listens',
    titleHe: 'מקשיב',
    prefix: 'to',
    prefixHe: 'ל',
    title_male: 'Listens',
    title_female: 'Listens',
    titleHe_male: 'מקשיב',
    titleHe_female: 'מקשיבה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🥊',
    title: 'Hits',
    titleHe: 'נותן מכה',
    prefix: '',
    prefixHe: 'ל',
    title_male: 'Hits',
    title_female: 'Hits',
    titleHe_male: 'נותן מכה',
    titleHe_female: 'נותנת מכה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '⛏',
    title: 'Annoying',
    titleHe: 'חופר',
    prefix: '',
    prefixHe: 'ל',
    title_male: 'Annoying',
    title_female: 'Annoying',
    titleHe_male: 'חופר',
    titleHe_female: 'חופרת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '🏀',
    title: 'Plays',
    titleHe: '',
    prefix: 'with',
    prefixHe: 'עם',
    title_male: 'Plays',
    title_female: 'Plays',
    titleHe_male: 'משחק',
    titleHe_female: 'משחקת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '💬',
    title: 'talk',
    titleHe: '',
    prefix: 'with',
    prefixHe: 'עם',
    title_male: 'talk',
    title_female: 'talk',
    titleHe_male: 'מדבר',
    titleHe_female: 'מדברת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🤐',
    title: "Isn't speaking",
    titleHe: 'לא מדבר',
    prefix: 'with',
    prefixHe: 'מ',
    title_male: "Isn't speaking",
    title_female: "Isn't speaking",
    titleHe_male: 'לא מדבר',
    titleHe_female: 'לא מדברת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🤭',
    title: 'Hiding something',
    titleHe: 'מסתיר משהו',
    prefix: 'from',
    prefixHe: 'מ',
    title_male: 'Surprised',
    title_female: 'Surprised',
    titleHe_male: 'מסתיר משהו',
    titleHe_female: 'מסתירה משהו'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🤥',
    title: 'Lying',
    titleHe: 'משקר',
    prefix: 'to',
    prefixHe: 'ל',
    title_male: 'Surprised',
    title_female: 'Surprised',
    titleHe_male: 'משקר',
    titleHe_female: 'משקרת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '😢',
    title: 'Offended',
    titleHe: 'נעלב',
    prefix: 'from',
    prefixHe: 'מ',
    title_male: 'Insults',
    title_female: 'Insults',
    titleHe_male: 'נעלב',
    titleHe_female: 'נעלבת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🙄',
    title: 'inconsiderate',
    titleHe: '',
    prefix: 'with',
    prefixHe: 'ב',
    title_male: 'inconsiderate',
    title_female: 'inconsiderate',
    titleHe_male: 'לא מתחשב',
    titleHe_female: 'לא מתחשבת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '🤲',
    title: 'Explain',
    titleHe: '',
    prefix: 'to',
    prefixHe: 'ל',
    title_male: 'Explain himself',
    title_female: 'Explain herself',
    titleHe_male: 'מסביר את עצמו',
    titleHe_female: 'מסבירה את עצמה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '🙌',
    title: 'Show trust',
    titleHe: 'מראה אמון',
    prefix: 'towards',
    prefixHe: 'ב',
    title_male: 'Show trust',
    title_female: 'Show trust',
    titleHe_male: 'מראה אמון',
    titleHe_female: 'מראה אמון'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '🙏',
    title: 'Show gratitude',
    titleHe: 'מודה',
    prefix: 'to',
    prefixHe: 'ל',
    title_male: 'Show gratitude',
    title_female: 'Show gratitude',
    titleHe_male: 'מודה',
    titleHe_female: 'מודה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🐣',
    title: 'Belittling',
    titleHe: 'מקטין',
    prefix: '',
    prefixHe: 'את',
    title_male: 'Belittling',
    title_female: 'Belittling',
    titleHe_male: 'מקטין',
    titleHe_female: 'מקטינה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '⚔',
    title: 'Fights',
    titleHe: 'נלחם',
    prefix: 'with',
    prefixHe: 'מ',
    title_male: 'Fights',
    title_female: 'Fights',
    titleHe_male: 'נלחם',
    titleHe_female: 'נלחמת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '😡',
    title: 'Mad',
    titleHe: 'עצבני',
    prefix: 'at',
    prefixHe: 'על',
    title_male: 'Mad',
    title_female: 'Mad',
    titleHe_male: 'עצבני',
    titleHe_female: 'עצבנית'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🙄',
    title: 'Avoiding',
    titleHe: 'נמנע מלפגוש',
    prefix: '',
    prefixHe: 'את',
    title_male: 'Avoiding',
    title_female: 'Avoiding',
    titleHe_male: 'נמנע מלפגוש',
    titleHe_female: 'נמנעת מלפגוש'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🎓',
    title: 'Lectures',
    prefix: '',
    titleHe: 'מטיף',
    prefixHe: 'ל',
    title_male: 'Lectures',
    title_female: 'Lectures',
    titleHe_male: 'מטיף',
    titleHe_female: 'מטיפה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🃏',
    title: 'Tricks',
    prefix: '',
    titleHe: 'מרמה',
    prefixHe: 'את',
    title_male: 'Tricks',
    title_female: 'Tricks',
    titleHe_male: 'מרמה',
    titleHe_female: 'מרמה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🤬',
    title: 'Swears',
    prefix: 'at',
    titleHe: 'מקלל',
    prefixHe: 'את',
    title_male: 'Swears',
    title_female: 'Swears',
    titleHe_male: 'מקלל',
    titleHe_female: 'מקללת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🤔',
    title: 'being suspicious',
    titleHe: 'מתנהג בחשד',
    prefix: 'of',
    prefixHe: 'כלפי',
    title_male: 'being suspicious',
    title_female: 'being suspicious',
    titleHe_male: 'מתנהג בחשד',
    titleHe_female: 'מתנהגת בחשד'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '👉',
    title: 'Blames',
    prefix: '',
    titleHe: 'מאשים',
    prefixHe: 'את',
    title_male: 'Blames',
    title_female: 'Blames',
    titleHe_male: 'מאשים',
    titleHe_female: 'מאשימה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '😎',
    title: 'Pleased',
    titleHe: 'מרוצה',
    prefix: 'with',
    prefixHe: 'מ',
    title_male: 'Pleased',
    title_female: 'Pleased',
    titleHe_male: 'מרוצה',
    titleHe_female: 'מרוצה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '👷',
    title: 'Bosses',
    prefix: '',
    titleHe: 'מנהל',
    prefixHe: 'את',
    title_male: 'Bosses',
    title_female: 'Bosses',
    titleHe_male: 'מנהל',
    titleHe_female: 'מנהלת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🗣',
    title: 'Shouts',
    titleHe: 'צועק',
    prefix: 'at',
    prefixHe: 'על',
    title_male: 'Shouts',
    title_female: 'Shouts',
    titleHe_male: 'צועק',
    titleHe_female: 'צועקת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '😤',
    title: 'Being impatient',
    titleHe: 'חסר סבלנות',
    prefix: 'with',
    prefixHe: 'כלפי',
    title_male: 'Being impatient',
    title_female: 'Being impatient',
    titleHe_male: 'חסר סבלנות',
    titleHe_female: 'חסרת סבלנות'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '😟',
    title: "Anxious",
    titleHe: 'בחרדות',
    prefix: 'because of',
    prefixHe: 'בגלל',
    title_male: "Anxious",
    title_female: "Anxious",
    titleHe_male: 'בחרדות',
    titleHe_female: 'בחרדות'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '👻',
    title: 'Plant Fear',
    titleHe: 'זורע פחדים',
    prefix: 'in',
    prefixHe: 'ב',
    title_male: 'Plant Fear',
    title_female: 'Plant Fear',
    titleHe_male: 'זורע פחדים',
    titleHe_female: 'זורעת פחדים'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🤔',
    title: 'Patronizes',
    titleHe: 'מתנשא',
    prefix: '',
    prefixHe: 'על',
    title_male: 'Patronizes',
    title_female: 'Patronizes',
    titleHe_male: 'מתנשא',
    titleHe_female: ' מתנשאת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '☝',
    title: 'Demands',
    titleHe: 'דורש',
    prefix: 'from',
    prefixHe: 'מ',
    title_male: 'Demands',
    title_female: 'Demands',
    titleHe_male: 'דורש',
    titleHe_female: ' דורשת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '😳',
    title: 'Acting weird',
    titleHe: 'מתנהג מוזר',
    prefix: 'around',
    prefixHe: 'ליד',
    title_male: 'Acting weird',
    title_female: 'Acting weird',
    titleHe_male: 'מתנהג מוזר',
    titleHe_female: 'מתנהגת מוזר'
  },
  {
    cardType: 'HAPPENED',
    isPositive: true,
    emoji: '😌',
    title: 'Being patient',
    titleHe: 'מתנהג בסובלנות',
    prefix: 'towards',
    prefixHe: 'כלפי',
    title_male: 'Being patient',
    title_female: 'Being patient',
    titleHe_male: 'מתנהג בסובלנות',
    titleHe_female: 'מתנהגת בסובלנות'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '🤩',
    title: 'Shows off',
    titleHe: 'משוויץ',
    prefix: 'to',
    prefixHe: 'בפני',
    title_male: 'Shows off',
    title_female: 'Shows off',
    titleHe_male: 'משוויץ',
    titleHe_female: 'משוויצה'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '😒',
    title: 'Ignore',
    titleHe: 'מתעלם',
    prefix: '',
    prefixHe: 'מ',
    title_male: 'Ignore',
    title_female: 'Ignore',
    titleHe_male: 'מתעלם',
    titleHe_female: 'מתעלמת'
  },
  {
    cardType: 'HAPPENED',
    isPositive: false,
    emoji: '😔',
    title: 'Acting disappointed',
    titleHe: 'מאוכזב',
    prefix: 'of',
    prefixHe: 'מ',
    title_male: 'Acting disappointed',
    title_female: 'Acting disappointed',
    titleHe_male: 'מאוכזב',
    titleHe_female: 'מאוכזבת'
  }
];

const feelCardsRaw = [
  {
    cardType: 'FEEL',
    isPositive: false,
    description: '',
    emoji: '🤔',
    title: "I don't know",
    titleHe: 'אני לא יודע/ת'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: '',
    emoji: '🙊',
    title:  "I don't want to share",
    titleHe: 'לא בא לי לשתף'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: '',
    emoji: '😶',
    title: 'Nothing',
    titleHe: 'שום דבר'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'A profoundly affection for another person.',
    emoji: '❤️',
    title: 'Love',
    titleHe: 'אהבה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Crushed with sorrow or grief.',
    emoji: '💔',
    title: 'Heart Broken',
    titleHe: 'שברון לב'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: '',
    emoji: '👎',
    title: 'Not fair',
    titleHe: 'לא פייר'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    emoji: '🏆',
    title: 'Proud',
    titleHe: 'גאווה'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'Inspired with courage, spirit, or confidence.',
    emoji: '🌺',
    title: 'Meaningful',
    titleHe: 'משמעות'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'Guarded, taken care of, embraced and shielded.',
    emoji: '🛡',
    title: 'Protected',
    titleHe: 'ביטחון'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'Having a warm and loving sensation.',
    emoji: '😊',
    title: 'Affection',
    titleHe: 'חום'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'Having a warm and loving sensation.',
    emoji: '🐰',
    title: 'Cute',
    titleHe: 'חמידות'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'Being relaxed, in both body and mind, without stress or hard emotions.',
    emoji: '😌',
    title: 'Calm',
    titleHe: 'רוגע'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Enraged; greatly provoked or irritated, angry.',
    emoji: '😡',
    title: 'Anger',
    titleHe: 'כעס'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'An expression or appearance of merriment or amusement.',
    emoji: '😆',
    title: 'Laughter',
    titleHe: 'צחוק'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'To be physically drawn to another.',
    emoji: '😘',
    title: 'Attraction',
    titleHe: 'משיכה'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'Admire the other.',
    emoji: '👍',
    title: 'Appriciation',
    titleHe: 'הערכה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: '',
    emoji: '👎',
    title: 'Booh',
    titleHe: 'בוז'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Admire the other.',
    emoji: '😤',
    title: 'Frustration',
    titleHe: 'תסכול'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'A feeling of esteem and admiration for another.',
    emoji: '😌',
    title: 'Respectful',
    titleHe: 'כבוד'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'an exercise or activity for amusement or recreation.',
    emoji: '🏀',
    title: 'Playfulness',
    titleHe: 'משחקיות'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'a sudden feeling of wonder or astonishment',
    emoji: '😲',
    title: 'Surprised',
    titleHe: 'הפתעה'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'To weaken the character or nature of someone by excessive indulgence.',
    emoji: ' 👑',
    title: 'Spoiled',
    titleHe: 'פינוק'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: '',
    emoji: '😫',
    title: 'Stress',
    titleHe: 'לחץ'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'Feel great joy or delight',
    emoji: '🎉',
    title: 'Joy',
    titleHe: 'שמחה'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'Feeling or expressing gratitude.',
    emoji: ' 🙏',
    title: 'Thankful',
    titleHe: 'אסיר/ת תודה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Hurt by what feels like a deliberate act or display of disrespect',
    emoji: '😢',
    title: 'Insult',
    titleHe: 'עלבון'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Made by someone seem unimportant.',
    emoji: '🐣',
    title: 'Belittled',
    titleHe: 'הקטנה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Actively aggressive, fighting back.',
    emoji: '⚔',
    title: 'Offensive',
    titleHe: 'במגננה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Dull, without spirit.',
    emoji: '😔',
    title: 'Exhausted',
    titleHe: 'תשישות'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Feel fear or terror, frightened, alarmed.',
    emoji: '😨',
    title: 'Fear',
    titleHe: 'פחד'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    description: 'not broken, damaged, or impaired; intact',
    emoji: '🌝',
    title: 'Whole',
    titleHe: 'שלמות'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Mislead by a false appearance or statement',
    emoji: '😧',
    title: 'Deceived',
    titleHe: 'מרומה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'A strong distaste, nausea, loathing.',
    emoji: '🤢',
    title: 'Disgust',
    titleHe: 'גועל'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Feel a painful loss of pride, self-respect, or dignity',
    emoji: '😳',
    title: 'Humiliated',
    titleHe: 'השפלה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Expressing a strong dislike.',
    emoji: ' 👿',
    title: 'Hatred',
    titleHe: 'שנאה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description:
      "Feeling resentment against someone because of that person's rivalry, success, or advantage.",
    emoji: '😒',
    title: 'Jealous',
    titleHe: 'קינאה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'Distrustful, questioning another person.',
    emoji: ' 🤔',
    title: 'Suspicious',
    titleHe: 'חשד'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'to leave completely and finally; forsake utterly; desert',
    emoji: '🙇‍♂️',
    title: 'Abandoned',
    titleHe: 'נטישה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    description: 'a feeling of responsibility or remorse',
    emoji: '⚖️',
    title: 'Guilt',
    titleHe: 'אשם/ה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '😔',
    title: 'Disappointment',
    titleHe: 'אכזבה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '😥',
    title: 'Sad',
    titleHe: 'עצב'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    emoji: '🙂',
    title: 'Satisfied',
    titleHe: 'שביעות רצון'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '😕',
    title: 'Longing',
    titleHe: 'געגוע'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '😰',
    title: 'Anxious',
    titleHe: 'חרדה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '😞',
    title: 'Sorry',
    titleHe: 'חרטה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '🙁',
    title: 'Bummed out',
    titleHe: 'באסה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '😞',
    title: 'Depressed',
    titleHe: 'דיכאון'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '😭',
    title: 'cry',
    titleHe: 'בוכה'
  },
  {
    cardType: 'FEEL',
    isPositive: false,
    emoji: '😔',
    title: 'Despare',
    titleHe: 'ייאוש'
  },
  {
    cardType: 'FEEL',
    isPositive: true,
    emoji: '🙏',
    title: 'Hope',
    titleHe: 'תקווה'
  }
];

const needCardsRaw = [
  {
    cardType: 'NEED',
    isPositive: false,
    emoji: '🤔',
    title: "I don't know",
    titleHe: 'אני לא יודע/ת'
  },
  {
    cardType: 'NEED',
    isPositive: false,
    emoji: '🙊',
    title:  "I don't want to share",
    titleHe: 'לא בא לי לשתף'
  },
  {
    cardType: 'NEED',
    isPositive: false,
    emoji: '😶',
    title: 'Nothing',
    titleHe: 'שום דבר'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '❤️',
    title: 'Love me',
    titleHe: 'שיאהבו אותי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '💗',
    title: 'To Love',
    titleHe: 'לאהוב'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '💎',
    title: 'Clarity',
    titleHe: 'בהירות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '👁️',
    title: 'To be seen',
    titleHe: 'שיראו אותי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🤔',
    title: 'To understand',
    titleHe: 'להבין'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😇',
    title: 'To be understood',
    titleHe: 'שיבינו אותי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🤝',
    title: 'Get support',
    titleHe: 'לקבל תמיכה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '⏳',
    title: 'Patience',
    titleHe: 'סבלנות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🕑',
    title: 'Time',
    titleHe: 'זמן'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🔇',
    title: 'Quiet',
    titleHe: 'שקט'
  },
  
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🙌',
    title: 'Receive trust',
    titleHe: 'שיאמינו בי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🔋',
    title: 'Energy',
    titleHe: 'אנרגיה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '✋',
    title: 'Help',
    titleHe: 'עזרה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😊',
    title: 'Attraction',
    titleHe: 'חום'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😴',
    title: 'Rest',
    titleHe: 'מנוחה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😀',
    title: 'To be happy',
    titleHe: 'לשמוח'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🤣',
    title: 'To laugh',
    titleHe: 'לצחוק'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '✋',
    title: 'Attention',
    titleHe: ' תשומת לב'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😊',
    title: 'Kindness',
    titleHe: 'אדיבות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😌',
    title: 'Affection towards me',
    titleHe: 'שיפגינו כלפיי חיבה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🕊️',
    title: 'Freedom',
    titleHe: 'חופשיות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '✊',
    title: 'Independence',
    titleHe: 'עצמאות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🏁',
    title: 'Challenge',
    titleHe: 'אתגר'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🎨',
    title: 'Creativity',
    titleHe: 'יצירתיות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🙏',
    title: 'Hope',
    titleHe: 'תקווה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🎯',
    title: 'Efficiency',
    titleHe: 'יעילות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🎓',
    title: 'To learn',
    titleHe: 'ללמוד'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🍀',
    title: 'Luck',
    titleHe: 'מזל'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '👏',
    title: 'Receive appreciation',
    titleHe: 'לקבל הערכה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🧟',
    title: 'To feel alive',
    titleHe: 'להרגיש חי/ה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🤝',
    title: 'Friendship',
    titleHe: 'חברות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '👨‍👩‍👧‍👦',
    title: 'My friends',
    titleHe: 'החברים שלי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🌡',
    title: 'Calm down',
    titleHe: 'להרגע'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🗺',
    title: 'Advanture',
    titleHe: 'הרפתקאה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🤩',
    title: 'Motivation',
    titleHe: 'מוטיבציה'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😌',
    title: 'Receive respect',
    titleHe: 'לקבל כבוד'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😇',
    title: 'Honesty with me',
    titleHe: 'כנות איתי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🤠',
    title: 'Authority',
    titleHe: 'שיתנו לי סמכות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🔗',
    title: 'Connection',
    titleHe: 'קשר'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🎉',
    title: 'Cheer to me',
    titleHe: 'שיפרגנו לי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🤗',
    title: 'A Hug',
    titleHe: 'שיחבקו אותי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '✋',
    title: 'To be left alone',
    titleHe: 'שיניחו לי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🏠',
    title: 'Feel I belong',
    titleHe: 'להרגיש שייכות'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '👂',
    title: 'To be listened to',
    titleHe: 'שיקשיבו לי'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '🏀',
    title: 'To play',
    titleHe: 'לשחק'
  },
  {
    cardType: 'NEED',
    isPositive: true,
    emoji: '😘',
    title: 'A Kiss',
    titleHe: 'נשיקה'
  }
  
];

const CARD_TYPE_TO_SURVEY_MAP = {
  HAPPENED: 'surveyHappened',
  FEEL: 'surveyFeeling',
  NEED: 'surveyNeeds'
};

const SURVEY_TO_CARD_TYPE_MAP = {
  surveyHappened: 'HAPPENED',
  surveyFeeling: 'FEEL',
  surveyNeeds: 'NEED'
};


const CARD_TYPE_TO_MIRROR = {
  // FEEL: 'feelTowardsPartner' && 'feelTowardsSituation', // TODO: this doesn't work..!
  FEEL: 'feelTowardsPartner',
  NEED: 'whatYouNeed'
}

const MIRROR_TO_CARD_TYPE = {
  feelTowardsPartner: 'FEEL',
  feelTowardsSituation: 'FEEL',
  whatYouNeed : 'NEED'
}

const happenedCards = omitDeep(
  happenedCardsRaw,
  'title_male',
  'title_female',
  'title_other',
  'titleHe',
  'titleHe_male',
  'titleHe_female',
  'titleHe_other',
  'descriptionHe',
  'prefixHe'
).map(card => {
  card.prefix = card.prefix ? card.prefix : ' ';
  return card;
});
const feelCards = omitDeep(
  feelCardsRaw,
  'title_male',
  'title_female',
  'title_other',
  'titleHe',
  'titleHe_male',
  'titleHe_female',
  'titleHe_other',
  'descriptionHe',
  'prefixHe'
).map(card => {
  card.prefix = card.prefix ? card.prefix : ' ';
  return card;
});
const needCards = omitDeep(
  needCardsRaw,
  'title_male',
  'title_female',
  'title_other',
  'titleHe',
  'titleHe_male',
  'titleHe_female',
  'titleHe_other',
  'descriptionHe',
  'prefixHe'
).map(card => {
  card.prefix = card.prefix ? card.prefix : ' ';
  return card;
});
export {
  happenedCardsRaw,
  happenedCards,
  feelCardsRaw,
  feelCards,
  needCardsRaw,
  needCards,
  CARD_TYPE_TO_SURVEY_MAP,
  SURVEY_TO_CARD_TYPE_MAP,
  CARD_TYPE_TO_MIRROR,
  MIRROR_TO_CARD_TYPE
};
