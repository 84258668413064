import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import AnimalsCarousel from '../../components/AnimalsCarousel/AnimalsCarousel';
import GenderPicker from '../../components/GenderPicker/GenderPicker';
import TopNavbar from '../../components/TopNavbar/TopNavbar';
import './Language.scss';

function changeLanguage(i18n, langName, langId) {
    // console.log('i18n.translator.language', i18n.translator.language);
      if (!langId) { 
      langId = langName;
      i18n.changeLanguage(langId);
      document.body.setAttribute(`lang`, langId);
      return langId;
    }
}

    
function Language({ authContext }) {

    const { t, i18n } = useTranslation();
    const [langState, setlangState] = useState(i18n.translator.language)

    function localeHandler(i18n, langName){
        const langId = changeLanguage(i18n, langName)
        authContext.changeLanguage(langId, langName);
    }

    return (
    <>
      
      <div className="language-container texture">
        <div className="title noselect">
          <h1>{t(`language.title`)}</h1>
          {/* <h2>{t(`create_new_relationship.step1.subtitle`)}</h2> */}
        </div>
        <div className="langIllustration">
            <img
                alt={`catbears language`}
                src={"/assets/language/language-planet.png"}
                // src={"/assets/onboarding/creation-toolset.PNG"}
                // className="catbearsAngry"
            />
        </div>
        <div className="langButtons">
            <div className="langButton" onClick={() => {setlangState("en") ;localeHandler(i18n, "en")}} >
                <div className="langButton--name">English</div>
                <div className={"langButton--V " + (langState == "en" ? "langButton--V__visible": "langButton--V__hidden" )}>
                </div>
            </div>
            <div className="langButton" onClick={() => {setlangState("he") ;localeHandler(i18n, "he")}} >
                <div className="langButton--name">עברית</div>
                <div className={"langButton--V " + (langState == "he" ? "langButton--V__visible": "langButton--V__hidden" )}>
                </div>
            </div>
        </div>
        <div className="lang-footer">
    
          <Button
            classes={"lang-footer__button"}
            color={`#489de5`}
            backgroundColor={`white`}
            background
            text={t(`general.continue`)}
            // onClick={}
            to={`/`}
            isLink={true}
          />
        </div>
      </div>
    </>
  );
}

export default Language;
