import React from 'react';
import {withTranslation} from 'react-i18next';
import ReactDOM from 'react-dom';
import './index.scss';
import AppRouter from "./Router";
import * as serviceWorker from './serviceWorker';
import './i18n';
import client from "./GraphQLClient";
import {ApolloProvider} from "react-apollo";


const App = withTranslation()(AppRouter)
ReactDOM.render(
  (<ApolloProvider client={client}>
    <App/>
  </ApolloProvider>), document.getElementById(`root`));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();