import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import './HomePage.scss';
import TopNavbar from '../../components/TopNavbar/TopNavbar';
import {Link} from 'react-router-dom';
import AnimalsThumbnail from '../../components/AnimalsThumbnail/AnimalsThumbnail';
import {apiListRelationships} from '../../graphql/queries';
import gql from 'graphql-tag';
import {useQuery} from 'react-apollo';
import catbearsAngry from './catbears_angry.png';
import catbearsAngry2x from './catbears_angry@2x.png';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import SubscriptionModal from "../../components/SubscriptionModal/SubscriptionModal";
import Onboarding from "../../components/Onboarding/Onboarding";

function HomePage({authContext}) {

  const {loading, error, data: relationshipsData, refetch} = useQuery(gql(apiListRelationships));

  const [relationships, setRelationships] = useState([]);

  const [isFeedbackModalState, setIsFeedbackModalState] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const {t} = useTranslation();

  function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  }

  useEffect(() => {
    if (relationshipsData) {
      setRelationships(relationshipsData.listRelationships.relationships);
    }

  }, [relationshipsData, isFeedbackModalState]);

  useEffect(() => {
    refetch();
  }, [authContext.user.id])

  // const addRelationshipDisabled = !authContext.user.isSubscribed && relationships.length === 1
  const addRelationshipDisabled = false; // REMOVING PAYWALL 7.10.2023

  return (
    <>
      <FeedbackModal
        isFeedbackModalState={isFeedbackModalState}
        setIsFeedbackModalState={setIsFeedbackModalState}
      />

      <Onboarding relationships={relationships} authContext={authContext}/>

      <SubscriptionModal isOpen={isSubscriptionModalOpen}
                         onExit={() => setIsSubscriptionModalOpen(false)}
      />
      <TopNavbar
        showMenuButton={true}
        showNavBG={true}
        showLogo={true}
        authContext={authContext}
        user={authContext.user}
        showLangButton={true}
        showProfileButton={true}
        // showFeedbackModalButton={true}
        // isFeedbackModalState={isFeedbackModalState}
        // setIsFeedbackModalState={setIsFeedbackModalState}
        // showHomeButton={true}
      ></TopNavbar>
      <div className="homepage pageTopPadding pageLeftRightPadding noselect">
        <header className="homepage__header">
          <img
            alt={`catbearsAngry`}
            src={catbearsAngry}
            srcSet={`${catbearsAngry2x} 2x, ${catbearsAngry2x} 3x`}
            className="catbearsAngry"
          />
          <h1 className="text-10margin homepage__main-question noselect">{t(`homepage.main-question`)}</h1>
          <p className="explainTerm noselect">{t('homepage.explainTerm')}</p>
        </header>

        <ul className="homepage__list noselect">
          {relationships.map((relationship, index) => {
            return (
              <Link
                key={index}
                to={{
                  pathname: `/relationship/${relationship.id}`,
                  state: {relationship}
                }}
              >
                <li className="homepage__list-item fade-in">
                  <AnimalsThumbnail
                    animalId={relationship.animalId}
                    partnerAnimalId={relationship.partnerAnimalId}
                  ></AnimalsThumbnail>
                  <p>
                    {t(`homepage.meAndPartner`, {
                      name: relationship.name,
                      partnerName: relationship.partnerName
                    })}
                  </p>
                </li>
              </Link>
            );
          })}
          <Link className="add-button" to={!addRelationshipDisabled ? `/relationship/new` : ''}>
            <li className="homepage__list-item homepage__list-item--create">
              <button
                onClick={addRelationshipDisabled ? () => {
                  setIsSubscriptionModalOpen(true)
                } : null}>{`+`}</button>
              <p>{t(`homepage.addRelationship`)}</p>
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
}

export default HomePage;
