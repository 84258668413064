import React, { useState } from 'react';
import './Q_RatingNumeric.scss';

const Q_RatingNumeric = ({ maxNum, textBelowMin, textBelowMax, onSelect }) => {

  const [rating, setRating] = useState(0);
  const [selectedRange, setSelectedRange] = useState(null);

  const handleRangeSelection = (selected) => {
    if (selected === selectedRange) {
      // Deselect the button if it's already selected
      setSelectedRange(null);
    } else {
      setSelectedRange(selected);
      setRating(selected);
      onSelect(selected);
    }
  };

  const renderRatingButtons = () => {
    const buttons = [];

    for (let i = 1; i <= maxNum; i++) {
      buttons.push(
        <div
          className={`rating-button selection-button-style ${selectedRange === i ? 'selected' : ''}`}
          key={i}
          onClick={() => {
            handleRangeSelection(i)}}
        >
          <span>{i}</span>
        </div>
      );
    }

    return buttons;
  };

  return (
    <div className="rating-container">
      <div className="rating-container__buttons">
        {renderRatingButtons()}
      </div>
      <div className="rating-container__texts">
        {textBelowMin && (
          <div className="rating-text">
            <span>{textBelowMin}</span>
          </div>
        )}
        {textBelowMax && (
          <div className="rating-text">
            <span>{textBelowMax}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Q_RatingNumeric;
