import { Machine, assign } from 'xstate';
import updateArray from 'utils/updateArray';
import getStepsStatus from 'utils/getStepsStatus';

const updateStepActions = ['updateValueOnQuest', 'setIsCompletedFalse', 'validateMandatoryFields'];

export default Machine(
  {
    id: 'quest',
    initial: 'STATE_unknown',
    states: {
      STATE_unknown: {
        entry: ['unknownLog', 'handleCTAstate'],
        always: [
          {
            cond: 'isSingleStepGuard',
            target: 'STATE_singleStetp'
          },
          {
            cond: 'isLastStepGuard',
            target: 'STATE_lastStep'
          },
          {
            cond: 'isFirstStepGuard',
            target: 'STATE_firstStep'
          },
          {
            target: 'STATE_middleStep'
          }
        ]
      },
      STATE_singleStetp: {
        entry: ['CTAtextState_finish', 'disableBACK'],
        on: {
          CTA: {
            actions: ['handleNextStep', 'updateIsCompletedOnJourney'],
            target: 'STATE_exit'
          },
          UPDATE_STEP: {
            actions: updateStepActions,
            target: 'STATE_unknown'
          }
        }
      },
      STATE_firstStep: {
        entry: ['CTAtextState_start', 'disableBACK'],
        on: {
          CTA: {
            actions: ['handleNextStep', 'updateIsCompletedOnJourney', 'updateStepsValues'],
            target: 'STATE_unknown'
          },
          UPDATE_STEP: {
            actions: updateStepActions,
            target: 'STATE_unknown'
          }
        }
      },
      STATE_middleStep: {
        entry: ['CTAtextState_continue', 'enableBACK'],
        on: {
          CTA: {
            actions: ['handleNextStep', 'updateIsCompletedOnJourney', 'updateStepsValues'],
            target: 'STATE_unknown'
          },
          BACK: {
            actions: 'handlePrevStep',
            target: 'STATE_unknown'
          },
          UPDATE_STEP: {
            actions: updateStepActions,
            target: 'STATE_unknown'
          }
        }
      },
      STATE_lastStep: {
        entry: ['CTAtextState_finish', 'enableBACK'],
        on: {
          CTA: {
            actions: ['handleNextStep', 'updateIsCompletedOnJourney'],
            target: 'STATE_exit'
          },
          BACK: {
            target: 'STATE_unknown',
            actions: 'handlePrevStep'
          },
          UPDATE_STEP: {
            actions: updateStepActions,
            target: 'STATE_unknown'
          }
        }
      },
      STATE_exit: {
        entry: 'exitQuest',
        type: 'final'
      }
    }
  },
  {
    guards: {
      isSingleStepGuard: function (context) {
        // console.log("isLastStepGuard: ", context.selectedStepNumberContext == context.stepsContext.length - 1)
        console.log("context.stepsContext.length ", context.stepsContext.length)
        return context.stepsContext.length == 1;
      },
      isLastStepGuard: function (context) {
        // console.log("isLastStepGuard: ", context.selectedStepNumberContext == context.stepsContext.length - 1)
        return context.selectedStepNumberContext == context.stepsContext.length - 1;
      },
      isFirstStepGuard: function (context) {
        // console.log("isFirstStepGuard : ", context.selectedStepNumberContext === 0)
        return context.selectedStepNumberContext === 0;
      }
    },
    actions: {
      unknownLog: function (context) {
        // console.log("STATE_unknown, context.selectedStepNumberContext: ", context.selectedStepNumberContext)
      },
      updateStepsValues: assign(function (context) {
        const { selectedStepNumberContext } = context;
        return {
          selectedStepNumberContext: selectedStepNumberContext + 1
        };
      }),
      handleNextStep: assign(function (context) {
        return { stepsContext: setIsCompletedOnStepContext(context, true) };
      }),
      handlePrevStep: assign(function (context) {
        if (context.selectedStepNumberContext > 0) {
          const { stepsContext, selectedStepNumberContext } = context;
          let currentStep = stepsContext[selectedStepNumberContext];
          let prevStep = stepsContext[selectedStepNumberContext - 1];
          // console.log("back. prevStep: ", prevStep )
          // console.log("back. stepsContext: ", stepsContext )
          return {
            selectedStepNumberContext: selectedStepNumberContext - 1,
            stepsContext
          };
        } else {
          return {};
        }
      }),
      CTAtextState_start: assign(function () {
        return { CTAtextContext: 'start' };
      }),
      CTAtextState_continue: assign(function () {
        return { CTAtextContext: 'continue' };
      }),
      CTAtextState_finish: assign(function () {
        return { CTAtextContext: 'finish' };
      }),
      disableBACK: assign(function () {
        return { isBackContext: false };
      }),
      enableBACK: assign(function () {
        return { isBackContext: true };
      }),
      exitQuest: assign(function () {
        return { isExitContext: true };
      }),
      handleCTAstate: assign(function (context) {
        const { stepsContext, selectedStepNumberContext } = context;
        const currentStep = stepsContext[selectedStepNumberContext];
        return { isCTAContext: currentStep.isMandatory };
      }),
      setIsCompletedFalse: assign(function (context) {
        return {
          stepsContext: setIsCompletedOnStepContext(context, false),
          journey: updateJourneyIsCompleted(context, false)
        };
      }),
      updateIsCompletedOnJourney: assign(function (context) {
        return {
          stepsContext: setIsCompletedOnStepContext(context, true),
          journey: updateJourneyIsCompleted(context, true)
        };
      }),
      validateMandatoryFields: assign(function (context) {
        const { questId, journey, selectedStepNumberContext, stepsContext } = context;
        const stepStatus = getStepsStatus(journey, questId);
        return {
          stepsContext: updateArray(
            stepsContext,
            stepStatus[selectedStepNumberContext],
            selectedStepNumberContext
          )
        };
      }),
      updateValueOnQuest: assign(function (context, { value, stepItemFlow }) {
        const { state: stepItemName } = stepItemFlow;
        const { journey } = context;
        const updatedJourney = {
          ...journey,
          [stepItemName]: value
        };
        return { journey: updatedJourney };
      })
    }
  }
);

function updateJourneyIsCompleted(context, isCompleted) {
  const { journey, questId, selectedStepNumberContext } = context;
  const originalFlowState = journey.progress.flowState || [];
  //@ts-ignore
  const originalFlowStateQuestId = originalFlowState[questId] || [];
  const updatedQuestFlowState = updateArray(
    originalFlowStateQuestId,
    { isCompleted },
    selectedStepNumberContext
  );
  const updatedJourney = {
    ...journey,
    progress: {
      ...journey.progress,
      flowState: updateArray(originalFlowState, updatedQuestFlowState, questId)
    }
  };
  return updatedJourney;
}

function setIsCompletedOnStepContext(context, isCompleted) {
  const { stepsContext, selectedStepNumberContext } = context;
  const currentStep = stepsContext[selectedStepNumberContext];
  const updatedStepsContext = updateArray(
    stepsContext,
    { ...currentStep, isCompleted },
    selectedStepNumberContext
  );
  return updatedStepsContext;
}
