import React from 'react';
import "./ButtonIconText.scss";
import { Link } from 'react-router-dom'

function ButtonIconText({classes, text, icon, onClick, to}){
  return (   
    <Link to={to} onClick={onClick}>
        <div className={"ButtonIconText noselect " + classes}>
            <div className={"ButtonIconText__icon noselect " + classes}>
                <img src={icon} alt="img" /></div>
            <div className={"ButtonIconText__text noselect "}>{text}</div>
        </div>
    </Link>
  )
}

export default ButtonIconText;