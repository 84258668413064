import gql from 'graphql-tag';
import React, {useContext, useEffect, useState} from 'react';
import {useMutation} from 'react-apollo';
import {useTranslation} from 'react-i18next';
import './Onboarding.scss';
import {updateUserOnboardingComplete, updateUserType} from '../../graphql/mutations';
import Modal from '../Modal/Modal';
import UserContext from "../../pages/Login/UserContext";
import FullScreenStepper from "../FullScreenStepper/FullScreenStepper";
import {CardType, Locale, UserType} from "../../graphql/types";
import Q_Cards from '../Q_Elements/Q_Cards/Q_Cards';
import Q_ListSingleSelect from '../Q_Elements/Q_ListSingleSelect/Q_ListSingleSelect';
import safeSpaceImg from '../../assets/onboarding/HIW-felting-with-buddy.png'
import {isNil, noop} from "lodash";
import Register from "../../pages/Login/Register";
import Login from "../../pages/Login/Login";
import FeltLogo from "../../components/FeltLogo/FeltLogo";
import AnimalsFacing from '../../components/AnimalsFacing/AnimalsFacing';


const Onboarding = ({relationships, authContext}) => {
  const {t, i18n} = useTranslation();
  
  const {user, handleUserUpdate} = useContext(UserContext);

  const loginFunc = () => {user.isGuest && setIsLogin(true)} //TODO

  const {changeLanguage} = authContext;
  const [updateUserTypeMutation] = useMutation(gql(updateUserType));
  const [updateUserOnboardingCompleteMutation] = useMutation(gql(updateUserOnboardingComplete), {
    update(cache, {data: {setUserIsOnboardingComplete}}) {
      handleUserUpdate(setUserIsOnboardingComplete);
    }
  });

  const [shouldDisplayOnboardingModal, setShouldDisplayOnboardingModal] = useState(false)
  const [selectedLocale, setSelectedLocale] = useState()
  const [selectedUserType, setSelectedUserType] = useState();
  const [shouldDisplayWelcomeModal, setShouldDisplayWelcomeModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setShouldDisplayOnboardingModal(!user.isOnboardingComplete && relationships.length === 0)
  }, [user, relationships])

  const buildSteps = () => {
    return [
      { //WELCOME STEP
        title: t('onboarding.hero-title'),
        subtitle: t('onboarding.hero-subtitle'),
        notice: t('onboarding.hero-notice'),
        isHero: true,
        shouldLeftCenter: true,
        btn1Text: t('general.alreadyHaveAnAccount'),
        btn2Text: t('general.lets-begin'),
        // topLogo: true,
        // topLogoWhite: true,
        topContent: (
          <FeltLogo 
								color = "white"
								classes = "feltLogo"
								symbol= {true}
							/>
        ),
        //-- ROOM
        background: "Room",
        backgroundColor : "var(--color-skyblue)",
        color: "white",
        titleShadowColor: "rgba(4,2,133, 0.3)",
        footerBackgroundColor: "var(--color-skyblue)",
        footerStyle_BRIGHT : true, // footer
        footerStyle_XL : true,
        //-- ROOM

        isStage: true,
        // shouldHideFooter: true
        shouldFooterBackgroundTransp: true,
        isLayoutLeftRight: true,
        isRightElement: true,
        rightElementContent: (
          <div className="felt-hug-container">
            <img className="felt-hug noselect " src={"/assets/onboarding/felt-hug.png"}/>
          </div>
        ),
        // rightElementContent: (<
        //   AnimalsFacing 
        //     name= {t("onboarding.side1-name")}
        //     partnerName= {t("onboarding.side2-name")}
        //     animalId = "croc"
        //     partnerAnimalId= "rat"
        //     positionBottom={true}
        // />),

        //BUTTONS
        btn1Design: "link",
        btn1Text: t('general.alreadyHaveAnAccount'),
        btn1_action: loginFunc, //TODO: DANIEL I know this is reeaaly ugly! but I don't know how to run the func onContinue from here
        btn2Text: t('general.lets-begin'),
        btn2_action: "onContinue", //TODO: DANIEL I know this is reeaaly ugly! but I don't know how to run the func onContinue from here
      },
      { // LANG STEP
        stepDisabled: user.locale !== Locale.He,
        title: t(`language.title`),
        element: (<LanguageSelector onSelect={(locale) => setSelectedLocale(locale)}/>),
        isContinueDisabled: isNil(selectedLocale),
        onContinue: async () => {
          await i18n.changeLanguage(selectedLocale === Locale.He ? 'he-IL' : 'en-US');
          changeLanguage(selectedLocale);
        },
        isHero: false,
        //-- ROOM
        background: "Room",
        backgroundColor : "var(--color-skyblue)",
        color: "white",
        titleShadowColor: "rgba(4,2,133, 0.3)",
        footerBackgroundColor: "var(--color-skyblue)",
        footerStyle_BRIGHT : true, // footer
        
        //-- ROOM
        btn1Text: t('general.continue'),
        btn1_action: "onContinue", //TODO: I know this is reeaaly ugly! but I don't know how to run the func onContinue from here
      },
      { //WHO IS USING - STEP
        title: t("onboarding.whoUseFelt"),
        element: <UserTypeSelector onSelect={(userType) => setSelectedUserType(userType)} selected={selectedUserType}/>,
        isContinueDisabled: isNil(selectedUserType),
        onContinue: async () => {
          await updateUserTypeMutation({variables: {type: selectedUserType}})
        },
        isHero: false,
        shouldFooterStickToBottom: false,
        //-- ROOM
        background: "Room",
        backgroundColor : "var(--color-skyblue)",
        color: "white",
        titleShadowColor: "rgba(4,2,133, 0.3)",
        footerBackgroundColor: "var(--color-skyblue)",
        footerStyle_BRIGHT : true, // footer
        //-- ROOM
        // BUTTONS
        btn1Text: t('general.continue'),
        btn1_action: "onContinue", //TODO
      },
      
      // { // DUMMY - STEP
      //   title: t('general.continue'),
      //   isHero: true,
      //   shouldLeftCenter: true,
      //   element: (onContinue) => <div>
      //     <button onClick={onContinue}>button</button>
      //   </div>,
      //   shouldHideFooter: true,
      //   //-- ROOM
      //   background: "Room",
      //   backgroundColor : "var(--color-orange)",
      //   color: "white",
      //   titleShadowColor: "rgba(4,2,133, 0.3)",
      //   footerBackgroundColor: "var(--color-skyblue)",
      //   //-- ROOM
      // },
      {// EXPERTS STEP
        title: t('onboarding.experts.title'),
        subtitle: t('onboarding.experts.sub-title'),
        shouldLeftCenter: true,
        //-- ROOM
        background: "Room",
        backgroundColor : "var(--color-skyblue)",
        color: "white",
        titleShadowColor: "rgba(4,2,133, 0.3)",
        footerBackgroundColor: "var(--color-skyblue)",
        footerStyle_BRIGHT : true, // footer
        footerStyle_XL : true,
        //-- ROOM
        // BUTTONS
        btn1Text: t('general.continue'),
        btn1_action: "onContinue", //TODO

        shouldFooterBackgroundTransp: true,
        isLayoutLeftRight: true,
        // isRightElement: true,
        element: (
          <div className="academic-fields_container">
            <div className="field-item">
              <img className="felt-hug noselect " src={"/assets/onboarding/academic-field__cbt.jpg"}/>
              <p>{t('onboarding.experts.cbt')}</p>
              
            </div>
            <div className="field-item">
              <img className="felt-hug noselect " src={"/assets/onboarding/academic-field__sel.jpg"}/>
              <p>{t('onboarding.experts.sel')}</p>
            </div>
            <div className="field-item">
              <img className="felt-hug noselect " src={"/assets/onboarding/academic-field__children-psy.jpg"}/>
              <p>{t('onboarding.experts.children-psy')}</p>
            </div>
            <div className="field-item">
              <img className="felt-hug noselect " src={"/assets/onboarding/academic-field__mediation.jpg"}/>
              <p>{t('onboarding.experts.mediation')}</p>
            </div>
            <div className="field-item">
              <img className="felt-hug noselect " src={"/assets/onboarding/academic-field__nvc.jpg"}/>
              <p>{t('onboarding.experts.nvc')}</p>
            </div>
          </div>
        ),
      },
      { //CREATE ACCOUNT - STEP
        stepDisabled: !user.isGuest,
        title: t('loginFlow.title-to-login'),
        subtitle: t('loginFlow.sub-title-to-login'),
        isHero: false,
        shouldLeftCenter: "space-between",
        element: (onContinue) => (
          <>
            <div className="register--container">
              <Register
                shouldDisplayLogin={false}
                onSignUp={onContinue}
                isSkippable={true}
                onSkip={onContinue}
                dontShowTitle={true}
                hasAccout={loginFunc}
                // isBright={true}
              />
            </div>
          </>
        ),
        //-- ROOM
        background: "Room",
        backgroundColor : "var(--color-skyblue)",
        color: "white",
        titleShadowColor: "rgba(4,2,133, 0.3)",
        footerBackgroundColor: "var(--color-pink)",
        //-- ROOM
        shouldHideFooter: true
      },
      { // SAFE ENVIRONMENT - STEP
        title: t("onboarding.safeSpaceTitle"),
        subtitle: t("onboarding.safeSpaceSubTitle"),
        isHero: true,
        element: (
          <SafeSpaceComponent/>
      ),
        //-- ROOM
        background: "Room",
        backgroundColor : "var(--color-skyblue)",
        color: "white",
        titleShadowColor: "rgba(4,2,133, 0.3)",
        footerBackgroundColor: "var(--color-skyblue)",
        footerStyle_BRIGHT : true, // footer
        //-- ROOM
        // BUTTONS
        btn1Text: t('general.continue'),
        btn1_action: "onContinue", //TODO
      }
    ];
  }

  const onFinish = () => {
    setShouldDisplayWelcomeModal(true);
    setShouldDisplayOnboardingModal(false);
    updateUserOnboardingCompleteMutation({
      variables: {
        isOnboardingComplete: true
      }
    })
  }

  const onCloseLogin = () => {
    setIsLogin(false);
  }

  return (
    <>
      {shouldDisplayOnboardingModal &&
        <FullScreenStepper stepsElements={buildSteps()}
                           onFinish={onFinish}
                           loginFunc={() => user.isGuest && setIsLogin(true)}
        />
      }
      {shouldDisplayWelcomeModal && <WelcomeModal onClose={() => setShouldDisplayWelcomeModal(false)}/>}
      {isLogin && <Login onLogin={onCloseLogin} onClose={onCloseLogin} shouldAllowSwitchToRegister={false}/>}
    </>
  )
}




const UserTypeSelector = ({onSelect, selected}) => {
  const {t} = useTranslation();

  const cardProps = {cardType: CardType.UserType}
  const cardsArr = [
    {
      id: UserType.ParentAndChild,
      content: <div>🧒👨</div>,
      label: t("onboarding.meAndParent"),
      classes: 'emoji twoEmojies',
      onClick: () => onSelect(UserType.ParentAndChild)
    },
    {
      id: UserType.Child,
      content: <div>🧒</div>,
      label: t("onboarding.justMe"),
      classes: 'emoji',
      onClick: () => onSelect(UserType.Child)
    },
    {
      id: UserType.Parent,
      content: <div>👨</div>,
      label: t("onboarding.parent"),
      classes: 'emoji',
      onClick: () => onSelect(UserType.Parent)
    },
    {
      id: UserType.Other,
      content: <div>...</div>,
      label: t("onboarding.other"),
      onClick: () => onSelect(UserType.Other)
    }
  ];
  return (
    <>
      <Q_Cards
        cardsArr={cardsArr}
        selected={cardsArr.find(card => card.id === selected)}
        isMultiSelect={false}
      />
    </>
  )
}

const LanguageSelector = ({onSelect}) => {
  return (
    <>
      <div className="Q_Language">
        <div className="langIllustration">
          <img
            alt={`catbears language`}
            src={"/assets/language/language-planet.png"}
          />
        </div>
        <Q_ListSingleSelect
          isRandomizeAnswers={false}
          isOther={false}
          listArr={[
            {id: Locale.He, displayString: 'עברית'},
            {id: Locale.En, displayString: 'English'}
          ]}
          onSelect={(locale) => onSelect(locale)}
        />
      </div>
    </>
  )
}

const WelcomeModal = ({onClose}) => {
  const {t} = useTranslation();

  return (<Modal
      setIsModalState={() => onClose()}
      CONTENT_p={
        <>
          <h2>{t(`modal.onboarding.title`)}</h2>
          <p>{t(`modal.onboarding.paragraph`)}</p>
        </>
      }
      PRESET_Popup={true}
      imgBgColor={'var(--color-skyblue)'}
      CONTENT_img={'/assets/onboarding/felt-hug_cropped.png'}
      imgWidth={'220px'}
      imgPaddingTop={'10px'}
      imgMarginBottom={'35px'}
      btn1_text={t(`general.lets-begin`)}
      isBackground={true}
      isXbutton={true}
      isShowModal={true}
      isModalState={true}
      bottomBorderRadius={false}
    />
  )
}

const SafeSpaceComponent = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="onboarding_feltSafeSpace-container">
        <div className="onboarding_feltSafeSpace-container__title">
          <div className="onboarding_feltSafeSpace--seperator"></div>

        </div>
        <div className="onboarding_feltSafeSpace-container__bullets">
          <div className="onboarding_feltSafeSpace-container__bullet onboarding_feltSafeSpace-container_bullet">
            <div>
                <img
                  className="onboarding_feltSafeSpace-container__bullet--icon"
                  src={"/assets/icons/icon-v-green_circle_white_32.png"}
                  alt="Felt by The Catbears - How to use Felt"
            />
              </div>
            <p>{t("onboarding.safeSpace_guide-1")}</p>
          </div>
          <div className="onboarding_feltSafeSpace-container__bullet">
            {/* <div className="onboarding_feltSafeSpace-container_bullet--icon onboarding_feltSafeSpace-containerbullet--icon_alert"></div> */}
            <div>
                  <img
                    className="onboarding_feltSafeSpace-container__bullet--icon"
                    src={"/assets/icons/icon-alert-black_bg_white_32.png"}
                    alt="Felt by The Catbears - How to use Felt"
              />
          </div>
            <p>{t("onboarding.safeSpace_guide-2")}</p>
          </div>
        </div>


        <img
          className="onboarding_feltSafeSpace-container__img"
          src={safeSpaceImg}
          alt="Felt by The Catbears - How to use Felt"
        />
      </div>
    </>)
}

export default Onboarding;
