import React from "react";
import "./Q_Card.scss";

function Q_Card({ card, isSelected, onClick }) {
  const cardClassName = isSelected ? " selected" : "";

  return (
    <div className={"Q_Card selection-button-style noselect " + cardClassName + " " + card.classes} onClick={onClick}>
      <div className="Q_Card__content">{card.content}</div>
      <p className="Q_Card__label">{card.label}</p>
    </div>
  );
}


export default Q_Card;
