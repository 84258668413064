import React, {useContext, useEffect} from 'react';
import {Router, Route, Switch, withRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {
  NewRelationshipWizard,
  EditRelationshipWizard
} from './pages/NewRelationshipWizard/NewRelationshipWizard';

import withAuthentication from './pages/Login/withAuthentication';
import HomePage from './pages/HomePage/HomePage';
import UserInfo from './pages/Admin/UserInfo/UserInfo';
import Language from './pages/Language/Language';
import MapRelationship from './pages/MapRelationship/MapRelationship';
import Journey from './pages/Journey/Journey';
import Journeys from './pages/Journeys/Journeys';
import Summary from './pages/Summary/Summary';
import Quest from './pages/Quest/Quest';
import GameCount01 from './pages/GameTime/GameCount01';
import StoryTime from './pages/StoryTime/StoryTime';
import Welcome from './pages/Welcome/Welcome';
import Hiw from './pages/Hiw/Hiw';
import Faq from './pages/Faq/Faq';
import MyTime from './pages/MyTime/MyTime';
import InGameSideBarView from './pages/Views/InGameSideBarView';
import {ApolloProvider} from 'react-apollo';
import client from './GraphQLClient';
import UserContext from "./pages/Login/UserContext";

const history = createBrowserHistory();
const ScrollToTop = withRouter(({children, location: {pathname}}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});


function AppRouter(props) {

  const {user} = useContext(UserContext);
  const {authContext} = props;

  if (!user) return null;

  return (

      <Router history={history}>
        <ScrollToTop>
          <Route
            path={`/`}
            exact
            render={(props) => <HomePage {...props} user={user} authContext={authContext}/>}
          />
          <Route
            path={`/language`}
            exact
            render={(props) => <Language {...props} user={user} authContext={authContext}/>}
          />
          <Route
            path={`/hiw`}
            exact
            render={(props) => <Hiw {...props} user={user}/>}
          />
          <Route
            path={`/admin/userinfo`}
            exact
            render={(props) => <UserInfo {...props} user={user} authContext={authContext}/>}
          />
          <Route
            path={`/faq`}
            exact
            render={(props) => <Faq {...props} user={user}/>}
          />
          <Switch>
            <Route
              exact
              path={`/relationship/new`}
              render={(props) => <NewRelationshipWizard {...props} user={user}/>}
            />
            <Route
              exact
              path={`/relationship/:id`}
              render={(props) => {
                console.log('authContext', authContext);
                // return <Journeys {...props} user={user} /> // maayan's hack
                return (
                  <Journeys {...props} user={user} />
                  // <InGameSideBarView {...props} user={user}>
                  // <Journeys {...props} user={user} />
                  // </InGameSideBarView>
                );
              }}
            />
            <Route
              exact
              path={`/relationship/:id/journey/:journeyID/summary`}
              render={(props) => {
                console.log('authContext', authContext);
                return (
                  <Summary {...props} user={user}/>
                );
              }}
            />
            <Route
              exact
              path={`/relationship/:id/edit`}
              render={(props) => <EditRelationshipWizard {...props} user={user}/>}
            />
            <Route
              exact
              path={`/relationship/:id/map`}
              render={(props) => <MapRelationship {...props} user={user}/>}
            />
            <Route exact path={`/welcome`} render={(props) => <Welcome {...props} user={user}/>}/>
            {/* <Route
                exact
                path={`/playtime`}
                render={props => <Playtime {...props} user={user} />}
              /> */}
            {/* <Route
                exact
                path={`/quest`}
                render={props => <Quest {...props} user={user} />}
              />
              <Route
                exact
                path={`/journey`}
                render={props => <Journey {...props} user={user} />}
              /> */}
            <Route
              exact
              path={`/relationship/:id/journey/:journeyID`}
              render={(props) => (
                <InGameSideBarView {...props} user={user}>
                  <Journey {...props} user={user}/>
                </InGameSideBarView>
              )}
            />
            <Route
              exact
              path={`/relationship/:id/journey/:journeyID/quest/:questID`}
              render={(props) => (
                <InGameSideBarView {...props} user={user}>
                  <Quest {...props} user={user}/>
                </InGameSideBarView>
              )}
            />
            <Route
              exact
              path={`/gametime`}
              render={(props) => (
                // <InGameSideBarView {...props} user={user}>
                <GameCount01 {...props} user={user}/>
                // </InGameSideBarView>
              )}
            />
            <Route
              exact
              path={`/storytime`}
              render={(props) => (
                // <InGameSideBarView {...props} user={user}>
                <StoryTime {...props} user={user}/>
                // </InGameSideBarView>
              )}
            />
            {/* <Route
                exact
                path={`/relationship/:id/mirror/:mirrorId/journey/:journeyItem`}
                render={props => <InGameSideBarView {...props} user={user}><InGameSteps/></InGameSideBarView>}
              />   */}
            {/* <Route
                exact
                path={`/relationship/:id/triggers/:mirrorId`}
                render={props => <InGameSideBarView {...props} user={user} journeyItem={1} />}
              />   
              <Route
                exact
                path={`/relationship/:id/partnermirror/:mirrorId`}
                render={props => <InGameSideBarView {...props} user={user} journeyItem={2} />}
              />   
              <Route
                exact
                path={`/relationship/:id/partnertrigger/:mirrorId`}
                render={props => <InGameSideBarView {...props} user={user} journeyItem={3} />}
              />                                              */}
            <Route exact path={`/mytime`} render={(props) => <MyTime {...props} user={user}/>}/>
          </Switch>
        </ScrollToTop>
      </Router>
  );
}

// export default AppRouter;
export default withAuthentication(AppRouter);
