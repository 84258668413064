import { IsCompleted, Mirror as Journey } from 'graphql/types';
import JourneyFlow from 'pages/Journey/JourneyFlow.json';
import isEmpty from './isEmpty';

function getStepsStatus(questData: Journey, questID: number) {
  const questFlow = JourneyFlow.journeyItems[questID];
  // @ts-ignore
  const questsFieldsAreCompleted = questData.progress.flowState[questID] as IsCompleted[];
  return questFlow.steps.map((stepFlow, indx) => {
    const stepItemIsMandatoryFieldsFilled = stepFlow.stepItems.map((stepItem) => {
      // @ts-ignore
      return stepItem.mandatory ? !isEmpty(questData[stepItem.state]) : true;
    });
    const isCompleted = questsFieldsAreCompleted[indx].isCompleted as boolean;
    const areAllMandatoryFieldsCompleted = !stepItemIsMandatoryFieldsFilled.includes(false);
    return { isCompleted, isMandatory: areAllMandatoryFieldsCompleted };
  });
}

export default getStepsStatus;
