import React, {useContext, useState} from 'react';
import * as AuthState from './authState';
import {useTranslation} from 'react-i18next';
import {noop} from "lodash";
import UserContext from "./UserContext";
import {useMutation} from "react-apollo";
import {createUser, signUpGuestUser} from "../../graphql/mutations";
import gql from "graphql-tag";

const Register = ({
                    onSignUp,
                    shouldAllowSwitchToLogin = true,
                    onSwitchToLogin = (noop),
                    isSkippable,
                    onSkip,
                    dontShowTitle,
                    isBright,
                    hasAccout
                  }) => {

  const userContext = useContext(UserContext) || {};
  const [isLogin, setIsLogin] = useState(false);

  console.log('user context', userContext);
  const {user, saveUserToLocalStorage, setAuthData} = userContext;

  const [signUpGuestUserMutation, {loading: guestUserLoading}] = useMutation(gql(signUpGuestUser))
  const [signUpNewUserMutation, {loading: newUserLoading}] = useMutation(gql(createUser))

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');

  const {t} = useTranslation();

  const _onChangeEmail = (event) => {
    const {value} = event.target;
    setEmail(value)
  }

  const _onChangePassword = (event) => {
    const {value} = event.target;
    setPassword(value);
  }

  const _onSignUp = async (event) => {
    event.preventDefault();

    const mutation = user.isGuest ? signUpGuestUserMutation : signUpNewUserMutation
    const resultParameterName = user.isGuest ? 'signUpGuestUser' : 'createUser'

    try {
      const result = await mutation({variables: {userInput: {email, password}}})
      const resultData = result.data[resultParameterName]
      const {token, user} = resultData
      saveUserToLocalStorage(token, user.id);
      setAuthData(resultData);

      onSignUp({email, password})

    } catch (error) {
      console.log('error signup', error);
      setError(error.message)
    }
  }

  const isLoading = guestUserLoading || newUserLoading;

  return (
    <>
    <div>
      <div className="noselect modal fade-in">
        {dontShowTitle ? "" : <h2 className="modal__title">{t("loginFlow.createAnAccount")}</h2> }
        <form onSubmit={_onSignUp} className="modal__form">
          <div
            className={"noselect modal__form--error-msg " + (error ? "" : " displayNone ")}>{error}</div>
          <div>
            <label className={"noselect form-label noselect "}>{t("loginFlow.email")}</label>
            <input type="email" className="input input-authMenu" value={email}
                   onChange={_onChangeEmail}></input>
          </div>
          <div>
            <label className={"noselect form-label noselect "}>{t("loginFlow.password")}</label>
            <input type="Password" className="input input-authMenu" value={password}
                   onChange={_onChangePassword}></input>
          </div>
          <div className="register_cta">
            <button
              className={"noselect register_cta__primary button button-primary modal__form--sumbit-button modal__form--sumbit-button__create-account" + (isLoading ? "preloaderGif " : " ") + " " + (isBright ? "button-primary-BRIGHT" : "")}
              type="submit">{isLoading ? "" : t("loginFlow.createAnAccount")}
            </button>
            
          </div>
          <div className="modal__below-submit--micro-text">{t("loginFlow.byCreating") + " "}
            <a className={"link_no-button " + (isBright ? "link_BRIGHT" : "")}>{t("loginFlow.privacyPolicy")}
            </a>{" " + t("loginFlow.and")}<a className={"noselect link_no-button " + (isBright ? "link_BRIGHT" : "")}>{t("loginFlow.TermsOfService")}</a>
            {" " + t("loginFlow.endOfSentence")}
          </div>
        </form>
        <div className="below-Modal noselect ">
          {(isSkippable && onSkip) && <div className={"noselect register_cta__skip link_no-button  " + (isBright ? "link_BRIGHT" : "")} onClick={onSkip}> {t('general.skip')} </div>}
          {shouldAllowSwitchToLogin && <p className="noselect register_mini-text">{t("loginFlow.alreadyAccount") + " "}
            <a className={"noselect link_no-button register_mini-text register_login-link" + (isBright ? "link_BRIGHT" : "")}
               onClick={hasAccout}>{t("loginFlow.logInBtn")}</a>
          </p>}
        </div>
      </div>
       
    </div>
    
    </>
  )
}

export default Register;